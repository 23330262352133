import {HttpService} from "../../App/services/http";
import {IAuthState} from "../interface/auth";

const login = (data: IAuthState) => {
  return HttpService.post("/auth/login", data, false)
    .then(response => {
      return response as { token: string }
    })
}

const logout = () => {
  return HttpService.post("/auth/logout")
    .then(response => {
      return response
    })
}

export const AuthServices = {
  login,
  logout
}