import {HttpService} from "../../App/services/http";
import {INotificationInput} from "../interfaces/inputs/notification.inputs.interface";
import {IFilter} from "../../App/interfaces/filter";

const items = (token: string, filter: IFilter) => {
  return HttpService.get(`/seller/${token}/notifications`, filter)
    .then(response => {
      return response
    })
}

const update = (id: number, data: INotificationInput) => {
  return HttpService.put(`/seller/${data.seller}/notification/${id}`, data)
    .then(response => {
      return response
    })
}

export const NotificationServices = {
  items,
  update
}