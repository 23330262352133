import React, {ReactElement} from "react"
import {Done as DoneIcon} from "@mui/icons-material";
import {
  Grid,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../App/hooks/store";
import {IInvoice} from "../../../interfaces/invoice";
import {ActionActionsTypes} from "../../../../App/interfaces/action";
import {InvoiceActions} from "../../../actions/invoice";
import {MenuItem} from "../../../../App/components/Menu";

interface Props {
  invoice: IInvoice,
  onChange?: (invoice: IInvoice) => void
}

export function Confirm(props: Props): ReactElement | null {
  const dispatch: any = useDispatch();
  const {seller} = useAppSelector(state => state.seller)
  const {invoice, onChange} = props

  const access = ['wait', 'error'].includes(invoice.status)

  return access ? (
    <Grid item>
      <MenuItem
        onClick={() => {
          dispatch({type: ActionActionsTypes.CREATE, payload: {
            message: 'Подтвердите действие',
            action: async () => {
              await dispatch(InvoiceActions.confirm(invoice.token, seller)).then(() => {
                if (onChange) {
                  onChange({
                    ...invoice,
                    status: 'paid',
                    updated: new Date()
                  })
                }
              })
            }}
          })
        }}
      >
        <ListItemIcon>
          <DoneIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText>Подтвердить платеж</ListItemText>
      </MenuItem>
    </Grid>
  ) : null
}
