import React, {ReactElement, useState} from "react"
import {Grid} from "@mui/material";
import {Merchant} from "../Merchant";
import {IMerchant} from "../../../interfaces/merchant";
import {Button} from "../../../../App/components/Input/Button";

interface Props {
  onChange?: (merchant: IMerchant) => void
}

export function Addition(props: Props): ReactElement | null {
  const {onChange} = props
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Grid item>
      <Button
        type="button"
        variant="contained"
        onClick={handleOpen}
      >
        Добавить
      </Button>
      {open ? (
        <Merchant
          open={open}
          onChange={(merchant: IMerchant) => {
            if (onChange) {
              onChange(merchant)
            }
          }}
          onClose={() => {
            setOpen(false);
          }}
        />
      ) : null}
    </Grid>
  )
}
