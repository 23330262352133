import React, {ReactElement, useState} from "react"
import {
  Grid,
  Fade,
  IconButton,
  MenuList
} from "@mui/material";
import {MoreVert as MoreVertIcon} from "@mui/icons-material";
import {IMerchant} from "../../../interfaces/merchant";
import {Edit} from "./Actions/Edit";
import {Delete} from "./Actions/Delete";
import {Menu} from "../../../../App/components/Menu";

interface Props {
  merchant: IMerchant,
  onChange?: (merchant: IMerchant) => void,
  onDelete?: (merchant: IMerchant) => void,
}

export function Actions(props: Props): ReactElement | null {
  const {merchant, onChange, onDelete} = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <IconButton onClick={handleClick}>
        <MoreVertIcon fontSize="small"/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClick={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Fade}
      >
        <MenuList dense>
          <Edit
            merchant={merchant}
            onChange={onChange}
          />
          <Delete
            merchant={merchant}
            onChange={(merchant) => {
              if (onDelete) {
                onDelete(merchant)
              }
            }}
          />
        </MenuList>
      </Menu>
    </Grid>
  )
}
