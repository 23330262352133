import {HttpService} from "../../App/services/http";
import {IMerchantInput} from "../interfaces/inputs/merchant.inputs.interface";
import {ISeller} from "../interfaces/seller";
import {IFilter} from "../interfaces/merchant/filter";

const all = (token: string) => {
  return HttpService.get(`/seller/${token}/merchants`)
    .then(response => {
      const {data} = response
      return data
    })
}

const items = (token: string, filter: IFilter) => {
  return HttpService.get(`/seller/${token}/merchants`, filter)
    .then(response => {
      return response
    })
}

const create = (data: IMerchantInput) => {
  return HttpService.post(`/seller/${data.seller}/merchant`, {
    ...data,
    merchant: data.merchant.key
  })
    .then(response => {
      const {data} = response
      return data
    })
}

const update = (id: number, data: IMerchantInput) => {
  return HttpService.put(`/seller/${data.seller}/merchant/${id}`, {
    ...data,
    merchant: data.merchant.key
  })
    .then(response => {
      return response
    })
}

const remove = (id: number, seller: ISeller) => {
  return HttpService.delete(`/seller/${seller.token}/merchant/${id}`)
    .then(response => {
      return response
    })
}

export const MerchantServices = {
  all,
  items,
  create,
  update,
  delete: remove
}