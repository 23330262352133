import {ReactElement} from "react";
import { alpha, useTheme } from '@mui/material/styles';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

type Props = PickersDayProps<Dayjs> & { dates?: Dayjs[] };

export function SelectedDay (props: Props): ReactElement {
  const theme = useTheme();
  const { dates = [], day } = props;
  const isSelected =
    dates.length === 0
      ? false
      : dates.length === 1
        ? day.isSame(dates[0], 'date')
        : day.isSameOrAfter(dates[0], 'date') &&
        day.isSameOrBefore(dates[1], 'date');

  return (
    <PickersDay
      {...props}
      selected={isSelected}
      day={day}
      sx={{
        borderRadius: '6px',
        backgroundColor: isSelected ? theme.palette.primary.main : 'inherit',
        color: isSelected ? theme.palette.primary.contrastText : 'inherit',
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.light, 0.4),
          color: theme.palette.primary.contrastText,
        },
        '&.Mui-selected': isSelected
          ? {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }
          : {
            backgroundColor: 'inherit',
            color: 'inherit',
          },
        '&.Mui-selected:hover': isSelected
          ? {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
          }
          : {
            backgroundColor: 'inherit',
            color: 'inherit',
          },
      }}
    />
  );
};