import React, {ReactElement, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {
  Grid,
} from "@mui/material";
import {useAppSelector} from "../../App/hooks/store";
import {IFilter as ISortFilter} from "../../App/interfaces/Table/filter";
import {Data, IData} from "../../App/interfaces/data";
import {INotification} from "../interfaces/notification";
import {IFilter} from "../../App/interfaces/filter";
import {Content} from "../../App/components/Papper/Content";
import {Point} from "../../App/components/Point";
import {useFilling} from "../../App/hooks/filling";
import {NotificationActions} from "../actions/notification";
import {Notification} from "./Notification/Noitifcation";

export function Notifications(): ReactElement | null {
  const [loading] = useFilling();
  const dispatch: any = useDispatch();
  const {seller} = useAppSelector(state => state.seller)
  const [filling, setFilling] = useState(false)
  const [params, setParams] = useState<{
    page: number,
    limit: number,
    search: string | null,
    filter: ISortFilter
  }>({page: 1, limit: 10, search: null, filter: {order: {name: 'id', direction: 'desc'}}})
  const [items, setItems] = useState<IData<INotification>>(Data)

  useEffect(() => {
    if (seller) {
      dispatch(NotificationActions.items(seller.token, {
        page: params.page,
        limit: params.limit,
        order: params.filter.order.name,
        direction: params.filter.order.direction,
      } as IFilter)).then((positions: IData<INotification>) => {
        if (filling) {
          setItems({
            data: [
              ...items.data,
              ...positions.data
            ],
            meta: positions.meta
          })
        } else {
          setItems(positions)
        }
      })

      setFilling(false)
    }
  }, [dispatch, seller, params]);

  useEffect(() => {
    if (filling) {
      setParams({
        ...params,
        page: params.page + 1
      })
    }
  }, [filling]);

  return (
    <React.Fragment>
      {items.data.map((item, index) => (
        <Grid item key={index}>
          <Content
            sx={{
              padding: '24px'
            }}
          >
            <Grid item xs={12}>
              <Notification item={item} />
            </Grid>
          </Content>
        </Grid>
      ))}
      {(items.data.length < items.meta.total) ? (
        <Point
          onCallback={() => {
            if (!loading) {
              setFilling(true)
            }
          }}
        />
      ) : null}
    </React.Fragment>
  )
}