import {Paper as MUIPaper, styled} from "@mui/material";

export const Content = styled(MUIPaper)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    width: 'calc(100vw - 48px)'
  },
  backgroundColor: 'rgb(255, 255, 255)',
  color: 'rgb(33, 43, 54)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
  borderRadius: '16px'
}));