import {Menu as MUIMenu, MenuItem as MUIMenuItem, styled} from "@mui/material";

export const Menu = styled(MUIMenu)(({theme}) => ({
  '& .MuiPaper-root': {
    boxShadow: 'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px'
  }
}))

export const MenuItem = styled(MUIMenuItem)(({theme}) => ({
  "&.Mui-disabled": {
    opacity: 0.70
  },
}))