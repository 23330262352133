import React, {ReactElement, useState} from "react"
import {AddLink} from "@mui/icons-material";
import {Grid, ListItemIcon, ListItemText} from "@mui/material";
import {IGateway} from "../../../../../Payment/interfaces/gateway";
import {MenuItem} from "../../../../../App/components/Menu";
import {Invoice} from "../../Invoice";

interface Props {
  gateway: IGateway
}

export function Payment(props: Props): ReactElement | null {
  const {gateway} = props
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Grid item>
      <MenuItem
        onClick={() => {
          handleOpen()
        }}
      >
        <ListItemIcon>
          <AddLink fontSize="small"/>
        </ListItemIcon>
        <ListItemText>Создать платеж</ListItemText>
      </MenuItem>
      {open ? (
        <Invoice
          gateway={gateway}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        />
      ) : null}
    </Grid>
  )
}
