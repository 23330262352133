import React, {ReactElement, useEffect, useState} from 'react';

import {
  Autocomplete, Chip as MUIChip, Grid, MenuItem, styled
} from "@mui/material";
import {Control, Controller, useFieldArray, useWatch} from "react-hook-form";
import {TextField} from "../../../../../App/components/Input/TextField";

export type Props = {
  control: Control,
  index: number,
  position: number,
  disabled: boolean
};

const Chip = styled(MUIChip)(({theme}) => ({
  borderRadius: '6px',
  color: 'rgba(0, 0, 0, 0.6)',
  fontSize: ' 0.75rem',
  fontWeight: 500,
  '&.MuiButtonBase-root': {
    backgroundColor: 'transparent',
    borderColor: 'rgba(0, 0, 0, 0.08)',
    border: '1px solid rgba(0, 0, 0, 0.08)'
  }
}));

export function Items (props: Props): ReactElement | null {
  const { control, index, position, disabled } = props

  const values: Array<string> = useWatch({
    control,
    name: `merchants.${index}.settings.product.${position}.items`
  })

  const [items, setItems] = useState<Array<string>>(values)

  useEffect(() => {
    setItems(values)
  }, [values?.length]);

  const { remove } = useFieldArray({
    control,
    name: `merchants.${index}.settings.product.${position}.items`
  });

  return (
    <React.Fragment>
      <Grid item xs={10}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <Grid item xs={4}>
            <Controller
              name={`merchants.${index}.settings.product.${position}.type`}
              control={control}
              render={({
                field: {onChange, value}, fieldState
              }) => (
                <TextField
                  select
                  disabled={disabled}
                  label="Тип:"
                  error={!!fieldState.error}
                  onChange={onChange}
                  value={value}
                  fullWidth
                >
                  {[
                    {value: 'exception', name: 'Исключение'},
                    {value: 'definition', name: 'Определение'},
                  ].map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={8}>
            <Controller
              name={`merchants.${index}.settings.product.${position}.items`}
              control={control}
              render={({
                field: {onChange, value}, fieldState
              }) => (
                <Autocomplete
                  fullWidth
                  freeSolo
                  multiple
                  disabled={disabled}
                  options={items}
                  loading={false}
                  value={value}
                  onChange={(e, value) => {
                    onChange(value)
                  }}
                  renderTags={() => null}
                  renderInput={(params) => (
                    <TextField
                      label="Идентификатор товара:"
                      {...params}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          {items?.map((option, index)=> (
            <Grid item key={index}>
              <Chip
                label={option}
                onDelete={() => {
                  remove(index)
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
