import {IRole} from "../../Auth/interface/role";
import {IPermission} from "../../Auth/interface/permission";
import {ISeller} from "../../Seller/interfaces/seller";

export interface IAccount {
  id: number,
  email: string,
  roles?: Array<IRole>
  permissions?: Array<IPermission>
  sellers?: Array<ISeller>
}

export enum AccountActionsTypes {
  FETCH_ACCOUNT = "FETCH_ACCOUNT",
  UPDATE_ACCOUNT_SELLER = "UPDATE_ACCOUNT_SELLER",
  CLEAR_ACCOUNT = "CLEAR_ACCOUNT"
}