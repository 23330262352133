import React, {ReactElement, useState} from "react"
import {Grid} from "@mui/material";
import {IGateway} from "../../../../Payment/interfaces/gateway";
import {Gateway} from "../Gateway";
import {Button} from "../../../../App/components/Input/Button";

interface Props {
  onChange?: (gateway: IGateway) => void
}

export function Addition(props: Props): ReactElement | null {
  const {onChange} = props
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Grid item>
      <Button
        type="button"
        variant="contained"
        onClick={handleOpen}
      >
        Добавить
      </Button>
      {open ? (
        <Gateway
          open={open}
          onChange={(gateway: IGateway) => {
            if (onChange) {
              onChange(gateway)
            }
          }}
          onClose={() => {
            setOpen(false);
          }}
        />
      ) : null}
    </Grid>
  )
}
