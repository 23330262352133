import React, {ReactElement} from "react"
import {
  Grid,
  Box,
  IconButton,
  Typography,
  styled
} from "@mui/material";
import {FilterList as FilterListIcon, Sort as SortIcon} from "@mui/icons-material";
import {IColumn} from "../../interfaces/Table/column";
import {IFilter} from "../../interfaces/Table/filter";
import {Interval} from "./Interval";
import {Options} from "./Options";
import {Autocomplete} from "./Autocomplete";
import {Popover} from "../Popover";

const Title = styled(Typography)(({theme}) => ({
  fontWeight: '400',
  fontSize: "0.8rem",
  color: "rgba(0, 0, 0, 0.6)",
  whiteSpace: "pre-wrap"
}))

interface Props {
  column: IColumn,
  filter: IFilter,
  setFilter: (filter: IFilter) => void
}

export function Filter(props: Props): ReactElement {
  const {column, filter, setFilter} = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sortHandler = (direction: 'asc' | 'desc') => {
    setFilter({
      ...filter,
      order: {name: column.key, direction: direction}
    });
  };

  const isOrdered = (filter.order.name === column.key)

  const getFilter = () => {
    switch (column.filter?.type) {
      case 'interval':
        return <Interval column={column} filter={filter} setFilter={setFilter} />
      case 'options':
        return <Options column={column} filter={filter} setFilter={setFilter} />
      case 'autocomplete':
        return <Autocomplete column={column} filter={filter} setFilter={setFilter} />
    }
  }

  const isActive = () => {
    if (isOrdered) {
      return true
    }

    switch (column.filter?.type) {
      case 'options':
        return !!filter.filters?.[column.key]?.length
      case 'interval':
        return (!isNaN(Number(filter.intervals?.[column.key]?.start)) || !isNaN(Number(filter.intervals?.[column.key]?.end)))
      case 'autocomplete':
        return !!Object.keys(filter.values?.[column.key] ?? {}).filter(name => !!filter.values?.[column.key][name].length).length
    }

    return false
  }

  return (
    <Box display="flex" alignItems="center">
      {column.label}
      <IconButton
        color={isActive() ? "primary" : "default" as 'primary' | 'default'}
        sx={{marginLeft: "12px", padding: "4px"}} edge="start" onClick={handleClick}>
        <FilterListIcon/>
      </IconButton>
      <Popover
        disableScrollLock
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
          {column.filter?.order ? (
            <Grid item>
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
                <Grid item>
                  <Title>
                    Сортировка
                  </Title>
                </Grid>
                <Grid item>
                  <IconButton
                    sx={{borderRadius: '6px'}}
                    color={(isOrdered && (filter.order.direction === 'desc')) ? "primary" : "default" as 'primary' | 'default'}
                    size="small"
                    onClick={() => sortHandler('desc')}
                  >
                    <SortIcon/>
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    sx={{borderRadius: '6px'}}
                    color={(isOrdered && (filter.order.direction === 'asc')) ? "primary" : "default" as 'primary' | 'default'}
                    size="small"
                    onClick={() => sortHandler('asc')}
                  >
                    <SortIcon sx={{transform: "rotateX(180deg)"}}/>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {getFilter()}
        </Grid>
      </Popover>
    </Box>
  )
}
