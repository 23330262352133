const getKey = (key: string) => process.env.REACT_APP_STORAGE_PREFIX ? `${process.env.REACT_APP_STORAGE_PREFIX}.${key}` : key;

export const set = (key: string, value: string) => localStorage.setItem(getKey(key), value)

export const get = (key: string): string | null => localStorage.getItem(getKey(key))

export const remove = (key: string) => localStorage.removeItem(getKey(key))

export const clear = () => {
  if (process.env.REACT_APP_STORAGE_PREFIX) {
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(`${process.env.REACT_APP_STORAGE_PREFIX}.`)) {
        localStorage.removeItem(key)
      }
    })
  } else {
    localStorage.clear()
  }
}
