import React, {ReactElement} from "react";
import {useAppDispatch, useAppSelector} from "../hooks/store";
import {IconButton, Snackbar as MUISnackbar, Stack, styled} from "@mui/material";
import {ActionActionsTypes} from "../interfaces/action";
import {Close as CloseIcon, Done as DoneIcon} from "@mui/icons-material";

const Snackbar = styled(MUISnackbar)(({theme}) => ({
  borderRadius: '8px',
  "& .MuiPaper-root": {
    color: 'rgb(33, 43, 54)',
    boxShadow: 'rgba(145, 158, 171, 0.16) 0px 8px 16px 0px',
    borderRadius: '8px',
    padding:'8px 16px',
    backgroundColor: 'rgb(255, 255, 255)',
  }
}))

export function Action(): ReactElement | null {
  const dispatch = useAppDispatch()

  const {action} = useAppSelector(state => state.action)

  const handleClose = () => {
    dispatch({type: ActionActionsTypes.DELETE})
  }

  return action ? (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={!!action}
        autoHideDuration={5000}
        onClose={handleClose}
        message={action.message}
        action={(
          <Stack direction="row" spacing={1}>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                new Promise((resolve) => {
                  handleClose()
                  resolve(true)
                }).then(
                  action.action
                )
              }}
            >
              <DoneIcon color="primary" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon color="error" />
            </IconButton>
          </Stack>
        )}
      />
    </React.Fragment>
  ) : null;
}
