export interface IAlert {
  type: "success" | "error" | "warning";
  title: string;
  message: string;
}

export enum AlertActionsTypes {
  SUCCESS = "ALERT_SUCCESS",
  WARNING = "ALERT_WARNING",
  ERROR = "ALERT_ERROR",
  RESET = "ALERT_RESET",
  SHIFT = "ALERT_SHIFT",
}