import React, {ReactElement} from "react"
import {IColumn} from "../../interfaces/Table/column";
import Grid from "@mui/material/Grid";
import {IFilter} from "../../interfaces/Table/filter";
import {Method} from "./Fields/Method";
import {Merchant} from "./Fields/Merchant";

interface Props {
  column: IColumn,
  filter: IFilter,
  setFilter: (filter: IFilter) => void
}

export function Autocomplete(props: Props): ReactElement {
  const {column, filter, setFilter} = props

  return (
    <Grid item sx={{ width: '300px'}}>
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
        {column.filter?.fields?.map((field, index) => {
          switch (field.type) {
            case 'method':
              return (
                <Grid key={index} item sx={{ width: '100%' }}>
                  <Method column={column} filter={filter} setFilter={setFilter} />
                </Grid>
              )
            case 'merchant':
              return (
                <Grid key={index} item sx={{ width: '100%' }}>
                  <Merchant column={column} filter={filter} setFilter={setFilter} />
                </Grid>
              )
          }
        })}
      </Grid>
    </Grid>
  )
}
