import {
  Dialog as MUIDialog,
  DialogActions as MUIDialogActions,
  DialogContent as MUIDialogContent,
  DialogTitle as MUIDialogTitle,
  styled
} from "@mui/material";

export const Dialog = styled(MUIDialog)(({theme}) => ({
  '& .MuiDialog-container': {
    '& .MuiPaper-root:not(.MuiAccordion-root)': {
      [theme.breakpoints.up('sm')]: {
        borderRadius: '16px',
      },
    }
  }
}));

export const DialogTitle = styled(MUIDialogTitle)(({theme}) => ({
  padding: '24px 24px 12px 24px',
  fontSize: '18px',
  lineHeight: 1.6,
  fontWeight: 700,
}));

export const DialogContent = styled(MUIDialogContent)(({theme}) => ({
  padding: '12px 24px 12px 24px !important',
  [theme.breakpoints.up('sm')]: {
    maxHeight: 'calc(100vh - 213px)',
  },
}));

export const DialogActions = styled(MUIDialogActions)(({theme}) => ({
  padding: '12px 24px 24px 24px'
}));