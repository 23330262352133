import {MethodServices} from "../services/method";
import {Dispatch} from "../../App/reducers/store";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {IMethod} from "../interfaces/method";

const all = () => {
  return (dispatch: Dispatch): Promise<Array<IMethod>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return MethodServices.all()
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

export const MethodActions = {
  all
}