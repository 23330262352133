import {MerchantServices} from "../services/merchant";
import {Dispatch} from "../../App/reducers/store";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {IData} from "../../App/interfaces/data";
import {IMerchant} from "../interfaces/merchant";
import {IMerchantInput} from "../interfaces/inputs/merchant.inputs.interface";
import {ISeller} from "../interfaces/seller";
import {AlertActionsTypes} from "../../App/interfaces/alert";
import {IFilter} from "../interfaces/merchant/filter";

const all = (token: string) => {
  return (dispatch: Dispatch): Promise<Array<IMerchant>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return MerchantServices.all(token)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const items = (token: string, filter: IFilter) => {
  return (dispatch: Dispatch): Promise<IData<IMerchant>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return MerchantServices.items(token, filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const create = (data: IMerchantInput) => {
  return (dispatch: Dispatch): Promise<IMerchant> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return MerchantServices.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS,
            payload: {
              type: "success",
              message: 'Платежная система добавлена',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const update = (id: number, data: IMerchantInput) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return MerchantServices.update(id, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS,
            payload: {
              type: "success",
              message: 'Данные обновлены',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const remove = (id: number, seller: ISeller) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return MerchantServices.delete(id, seller)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS,
            payload: {
              type: "success",
              message: 'Платежная система удалена',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

export const MerchantActions = {
  all,
  items,
  create,
  update,
  delete: remove
}