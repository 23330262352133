import React, {ReactElement, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {
  Grid,
  Chip as MUIChip,
  Stack,
  useMediaQuery,
  useTheme,
  styled, Typography
} from "@mui/material";
import {useAppSelector} from "../App/hooks/store";
import {IFilter as ISortFilter} from "../App/interfaces/Table/filter";
import {Data, IData} from "../App/interfaces/data";
import {IFilter} from "../App/interfaces/filter";
import {useDebouncedCallback} from "use-debounce";
import {GatewayActions} from "./actions/gateway";
import {IGateway} from "../Payment/interfaces/gateway";
import {Addition} from "./components/Gateway/Buttons/Addition";
import {Actions} from "./components/Gateway/Buttons/Actions";
import {TextField} from "../App/components/Input/TextField";
import {Title} from "../App/components/Typography/Title";
import {Content} from "../App/components/Papper/Content";
import {Point} from "../App/components/Point";
import {useFilling} from "../App/hooks/filling";

const Chip = styled(MUIChip)(({theme}) => ({
  borderRadius: '6px',
  color: theme.palette.primary.main,
  borderColor: 'rgba(145, 158, 171, 0.2)',
  fontSize: ' 0.75rem',
  fontWeight: 500
}));

export function Gateways(): ReactElement | null {
  const [loading] = useFilling();
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const {seller} = useAppSelector(state => state.seller)
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [filling, setFilling] = useState(false)
  const [params, setParams] = useState<{
    page: number,
    limit: number,
    search: string | null,
    filter: ISortFilter
  }>({page: 1, limit: 10, search: null, filter: {order: {name: 'index', direction: 'desc'}}})
  const [items, setItems] = useState<IData<IGateway>>(Data)

  const debounced = useDebouncedCallback(
    (value: string) => {
      setParams({
        ...params,
        page: 1,
        search: value
      })
    },
    900
  );

  useEffect(() => {
    if (seller) {
      dispatch(GatewayActions.items(seller.token, {
        page: params.page,
        limit: params.limit,
        order: params.filter.order.name,
        direction: params.filter.order.direction,
        ...(params.search ? {search: params.search} : {}),
        ...(params.filter.filters ? Object.entries(params.filter.filters).reduce((obj, [name, values]) => {
          return {
            ...obj,
            ...(values.length ? {[name]: values.join(',')} : {})
          }
        }, {}) : {})
      } as IFilter)).then((positions: IData<IGateway>) => {
        setItems(positions)
      })
    }
  }, [dispatch, seller, params]);

  useEffect(() => {
    if (filling) {
      setParams({
        ...params,
        page: params.page + 1
      })
    }
  }, [filling]);

  return (
    <Stack spacing={2}>
      <Grid item>
        <Title>
          Способы оплаты
        </Title>
      </Grid>
      <Grid item>
        <Grid container direction={mobile ? "column" : "row" as 'column' | "row"} spacing={2}>
          <Grid item xs={12}>
            <Content>
              <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{padding: '24px'}}>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                  <TextField
                    fullWidth
                    placeholder="Поиск"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      debounced(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item>
                  <Addition
                    onChange={(gateway) => {
                      setItems({
                        data: [gateway, ...items.data],
                        meta: {
                          ...items.meta,
                          total: items.meta.total + 1
                        }
                      })
                    }}
                  />
                </Grid>
              </Grid>
            </Content>
          </Grid>
          {items.data.map((item, index) => (
            <Grid key={index} item xs={12}>
              <Content>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{padding: '24px'}}>
                  <Grid item xs={10}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                      <Grid item xs={mobile ? 12 : 8}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                          <Grid item xs={6}>
                            <Typography
                              noWrap
                              sx={{
                                fontWeight: 400,
                                lineHeight: 1.57143,
                                fontSize: '0.75rem',
                                color: 'rgb(99, 115, 129)'
                              }}
                            >
                              Идентификатор
                            </Typography>
                            <Typography
                              noWrap
                              sx={{
                                fontWeight: 400,
                                fontSize: '0.875rem',
                                lineHeight: 1.43
                              }}
                            >
                              {item.number}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              noWrap
                              sx={{
                                fontWeight: 400,
                                lineHeight: 1.57143,
                                fontSize: '0.75rem',
                                color: 'rgb(99, 115, 129)'
                              }}
                            >
                              Способ оплаты
                            </Typography>
                            <Typography
                              noWrap
                              sx={{
                                fontWeight: 400,
                                fontSize: '0.875rem',
                                lineHeight: 1.43
                              }}
                            >
                              {item.method.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={mobile ? 12 : 4}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                          {item.merchants.map((merchant, index) => (
                            <Grid item key={index} >
                              <Chip label={merchant.name} variant="outlined"/>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Actions
                      gateway={item}
                      onChange={(gateway) => {
                        setItems({
                          ...items,
                          data: items.data.map(item => (item.id === gateway.id) ? gateway : item)
                        })
                      }}
                      onDelete={(gateway) => {
                        setItems({
                          data: items.data.filter(item => item.id !== gateway.id),
                          meta: {
                            ...items.meta,
                            total: items.meta.total - 1
                          }
                        })
                      }}
                    />
                  </Grid>
                </Grid>
              </Content>
            </Grid>
          ))}
          {(items.data.length < items.meta.total) ? (
            <Point
              onCallback={() => {
                if (!loading) {
                  setFilling(true)
                }
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </Stack>
  )
}