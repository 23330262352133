import React, {ReactElement, useEffect} from "react";
import {
  AlertActionsTypes,
  IAlert
} from "../interfaces/alert";
import {useAppDispatch, useAppSelector} from "../hooks/store";
import {Event} from "./Alert/Event";

export function Alerts(): ReactElement {
  const dispatch = useAppDispatch()

  const {events} = useAppSelector(state => state.alert)

  const [alert, setAlert] = React.useState<IAlert | null>();
  const [alerts, setAlerts] = React.useState<Array<IAlert>>([]);

  useEffect(() => {
    setAlerts(events)
  }, [events])

  useEffect(() => {
    if (!alert && alerts.length) {
      setAlert(alerts[0])
      dispatch({type: AlertActionsTypes.SHIFT})
    }
  }, [alert, alerts])

  return (
    <React.Fragment>
      {alert ? <Event event={alert} onClose={() => setAlert(null)}/> : null}
    </React.Fragment>
  );
}
