import React, {ReactElement} from 'react';
import {
  Grid, Box,
  Chip as MUIChip,
  IconButton,
  useTheme,
  styled,
  Typography
} from "@mui/material";
import {Control, useWatch} from "react-hook-form";
import {ContentCopy as ContentCopyIcon} from "@mui/icons-material";
import {IMerchant as IPaymentMerchant} from "../../../Payment/interfaces/merchant";
import {Chip} from "../../../App/components/Chip";

const Method = styled(MUIChip)(({theme}) => ({
  borderRadius: '6px',
  color: 'rgb(255, 152, 0)',
  borderColor: 'rgba(145, 158, 171, 0.2)',
  fontSize: ' 0.75rem',
  fontWeight: 500
}));

interface Props {
  control: Control
}

export function Routes(props: Props): ReactElement | null {
  const {control} = props;
  const theme = useTheme();

  const merchant: IPaymentMerchant = useWatch({
    control,
    name: 'merchant'
  })

  return merchant ? (
    <Grid item>
      <Grid container direction="column" alignItems="stretch" justifyContent="stretch" spacing={2}>
        <Grid item>
          <Box
            sx={{
              paddingBottom: theme.spacing(1.5),
              display: "flex",
              flexFlow: "row wrap",
              gridGap: theme.spacing(1)
            }}
          >
            {merchant.methods.map((method, index) => <Method key={index} label={method.short} variant="outlined"/>)}
          </Box>
        </Grid>
        {[
          {
            name: 'Оповещение об оплате',
            value: `${process.env.REACT_APP_HOST}/api/merchant/${merchant.key}/notification`,
            methods: ['POST']
          },
          {
            name: 'Переадресация после успешной оплата',
            value: `${process.env.REACT_APP_HOST}/merchant/${merchant.key}/success`,
            methods: ['GET', 'POST']
          },
          {
            name: 'Переадресация в случае неудачной оплата',
            value: `${process.env.REACT_APP_HOST}/merchant/${merchant.key}/fail`,
            methods: ['GET', 'POST']
          },
        ].map((item, index) => (
          <Grid item key={index}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography
                  noWrap
                  sx={{
                    fontWeight: 500,
                    lineHeight: 1.57143,
                    fontSize: '0.875rem'
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  noWrap
                  sx={{
                    maxWidth: '400px',
                    [theme.breakpoints.down('xl')]: {
                      maxWidth: '350px',
                    },
                    [theme.breakpoints.down('md')]: {
                      maxWidth: '500px',
                    },
                    [theme.breakpoints.down('sm')]: {
                      maxWidth: '250px',
                    },
                    lineHeight: 1.57143,
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    color: 'rgb(99, 115, 129)'
                  }}
                >
                  {item.value}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                  {item.methods.map((method, index) => (
                    <Grid item key={index}>
                      <Chip label={method}/>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={async () => {
                    await navigator.clipboard.writeText(item.value)
                  }}
                >
                  <ContentCopyIcon fontSize="small"/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  ) : null
}