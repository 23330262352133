import {ReactElement, useEffect} from 'react';
import {NavigateFunction, useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {AuthActions} from "./actions/auth";

export function Logout(): ReactElement | null {
  const dispatch: any = useDispatch();
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    const logout = async () => {
      return await dispatch(AuthActions.logout())
    }

    logout().then(() => navigate('/'))
  });

  return null
}