import React, {ReactElement, useEffect, useState} from "react"
import {Autocomplete} from "@mui/material";
import {MethodActions} from "../../../../Payment/actions/method";
import {useDispatch} from "react-redux";
import {IFilter} from "../../../interfaces/Table/filter";
import {IColumn} from "../../../interfaces/Table/column";
import {TextField} from "../../Input/TextField";

interface Props {
  column: IColumn,
  filter: IFilter,
  setFilter: (filter: IFilter) => void
}

export function Method(props: Props): ReactElement | null {
  const dispatch: any = useDispatch();
  const {column, filter, setFilter} = props
  const [values, setValues] = useState<Array<{ name: string, key: string }>>(filter.values?.[column.key]?.['method'] ?? [])

  const [methods, setMethods] = useState<Array<{ name: string, key: string }>>([])
  useEffect(() => {
    dispatch(MethodActions.all()).then((positions: Array<{ name: string, key: string }>) => {
      setMethods(positions)
    })
  }, []);

  return (
    <Autocomplete
      fullWidth
      multiple
      getOptionLabel={(option) => option.name ?? null}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      noOptionsText={"Нет данных"}
      options={methods}
      value={values}
      onChange={(event, value) => {
        setValues(value)
      }}
      onBlur={() => {
        if (filter.values?.[column.key]?.['method'] !== values) {
          setFilter({
            ...filter,
            values: {
              ...filter.values,
              [column.key]: {
                ...filter.values?.[column.key],
                method: values
              }
            }
          })
        }
      }}
      loading={false}
      renderTags={() => null}
      slotProps={{
        paper: {
          sx: {
            '& .MuiAutocomplete-listbox': {
              '& .MuiAutocomplete-option': {
                fontWeight: '400',
                fontSize: "0.8rem",
                color: "rgba(0, 0, 0, 0.6)"
              }
            }
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          sx={{
            '& .MuiInputBase-root': {
              padding: '0',
              maxHeight: '34px',
              '&.MuiAutocomplete-inputRoot': {
                padding: '0 4px',
              }
            },
            '& .MuiInputBase-input': {
              padding: '8px',
              fontSize: "0.8rem",
            },
            ...(values.length ? {
              '& ::placeholder': {
                color: 'rgba(0, 0, 0, 0.6)',
                opacity: '1 !important'
              }
            } : {})
          }}
          placeholder={values.length ? values.map(option => option.name).join(', ') : 'Способ оплаты'}
          {...params}
        />
      )}
    />
  )
}
