import React, {ReactElement} from "react"
import {Replay as ReplayIcon} from "@mui/icons-material";
import {Grid, ListItemIcon, ListItemText} from "@mui/material";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../App/hooks/store";
import {IInvoice} from "../../../interfaces/invoice";
import {InvoiceActions} from "../../../actions/invoice";
import {ActionActionsTypes} from "../../../../App/interfaces/action";
import {MenuItem} from "../../../../App/components/Menu";

interface Props {
  invoice: IInvoice
}

export function Notify(props: Props): ReactElement | null {
  const dispatch: any = useDispatch();
  const {seller} = useAppSelector(state => state.seller)
  const {invoice} = props

  const access = !['wait'].includes(invoice.status)

  return access ? (
    <Grid item>
      <MenuItem
        onClick={() => {
          dispatch({type: ActionActionsTypes.CREATE, payload: {
              message: 'Подтвердите действие',
              action: async () => {
                await dispatch(InvoiceActions.notify(invoice.token, seller))
              }}
          })
        }}
      >
        <ListItemIcon>
          <ReplayIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText>Отправить оповещение</ListItemText>
      </MenuItem>
    </Grid>
  ) : null
}
