import {ReactElement, useEffect, useState} from "react"
import * as AppStorage from "../App/helpers/storage"
import {NavigateFunction, useNavigate} from "react-router";
import {useAppSelector} from "../App/hooks/store";

interface Props {
  children: ReactElement
}

export function Guest(props: Props): ReactElement | null {
  const {children} = props
  const navigate: NavigateFunction = useNavigate();
  const {account} = useAppSelector(state => state.account)
  const [allowed, setAllowed] = useState(false);

  // eslint-disable-next-line
  useEffect(() => {
    const token: string | null = AppStorage.get('token');

    if (!token && !account) {
      setAllowed(true);
    } else {
      navigate('/');
    }
  });

  return allowed ? children : null
}
