import {HttpService} from "../../App/services/http";
import {ISettingsInput} from "../interfaces/inputs/settings.inputs.interface";
import {IDocumentationInput} from "../interfaces/inputs/documentation.inputs.interface";

const info = (token: string) => {
  return HttpService.get(`/seller/${token}/info`)
    .then(response => {
      const {data} = response
      return data
    })
}

const seller = (token: string) => {
  return HttpService.get(`/seller/${token}`)
    .then(response => {
      const {data} = response
      return data
    })
}

const update = (token: string, data: ISettingsInput) => {
  return HttpService.put(`/seller/${token}`, data)
    .then(response => {
      return response
    })
}
const documentation = (token: string, data: IDocumentationInput) => {
  return HttpService.put(`/seller/${token}/documentation`, data)
    .then(response => {
      return response
    })
}

export const SellerServices = {
  info,
  seller,
  update,
  documentation
}