import {TextField as MUITextField, TextFieldProps, styled, OutlinedInputProps} from "@mui/material";
import {red} from "@mui/material/colors";

export const TextField = styled((props: TextFieldProps) => (
  <MUITextField
    {...props}
  />
))(({theme}) => ({
  '& .MuiFormLabel-root': {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: "rgb(145, 158, 171)",
    '& .Mui-error': {
      color: "rgb(145, 158, 171)",
    },
    '&.Mui-error': {
      color: "rgb(145, 158, 171)",
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: "rgba(145, 158, 171, 0.2)",
    transition: 'border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-error fieldset': {
      borderColor: red[100],
    },
    '&:hover fieldset': {
      borderColor: 'rgba(38,108,205, 0.4)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-disabled fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.6)",
    borderRadius: '8px',
    '&.MuiAutocomplete-inputRoot': {
      padding: '7.5px',
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  },
  '& .MuiInputBase-input': {
    padding: '15px',
    fontSize: '0.875rem',
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.6)"
  },
  '& .MuiFormHelperText-root': {
    textAlign: 'right',
    color: 'rgb(145, 158, 171)',
    '&.Mui-error': {
      color: 'rgb(145, 158, 171)',
    },
  }
}));