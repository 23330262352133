import {AuthServices} from "../services/auth";
import * as AppStorage from "../../App/helpers/storage";
import {IAuthState} from "../interface/auth";
import {AccountActionsTypes} from "../../Account/interfaces/account";
import {Dispatch} from "../../App/reducers/store";
import {SellerActionsTypes} from "../../Seller/interfaces/seller";

const login = (data: IAuthState) => {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    return AuthServices.login(data)
      .then(
        (response) => {
          AppStorage.set('token', response.token)
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
  })
}

const logout = () => {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    return AuthServices.logout()
      .then(
        (response) => {
          AppStorage.clear()
          dispatch({type: AccountActionsTypes.CLEAR_ACCOUNT})
          dispatch({type: SellerActionsTypes.CLEAR_SELLER})
          resolve(true)
        },
        error => {
          reject(error)
        }
      )
  })
}

export const AuthActions = {
  login,
  logout
}