import React, {ReactElement} from "react";
import {
  IAlert
} from "../../interfaces/alert";
import {
  Typography, Grid,
  Snackbar as MUISnackbar,
  styled
} from "@mui/material";

const Description = styled(Typography)(({theme}) => ({
  color: "white",
  fontWeight: '400',
  fontSize: "0.875rem",
  whiteSpace: "pre-wrap"
}))

const Snackbar = styled(MUISnackbar, {
  shouldForwardProp: (prop) => true
})(({theme, color}) => ({
  borderRadius: '8px',
  "& .MuiPaper-root": {
    borderRadius: '8px',
    backgroundColor: color,
    color: theme.palette.primary.dark,
    boxShadow: 'none'
  }
}))

interface Props {
  event: IAlert,
  onClose: () => void
}

const getColor = (type: "success" | "error" | "warning") => {
  switch (type) {
    case "success":
      return 'rgba(0, 202, 134, 0.8)';
    case "error":
    case "warning":
      return 'rgba(183, 29, 24, 0.8)';
  }
}

export function Event(props: Props): ReactElement {
  const {event, onClose} = props
  const [open, setOpen] = React.useState(!!event);

  const handleClose = () => {
    setOpen(false);
    onClose()
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      onClose={handleClose}
      title={event.title}
      color={getColor(event.type)}
      message={
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
          <Grid item>
            <Description variant="caption">{event.title}</Description>
          </Grid>
          <Grid item>
            <Description variant="caption">{event.message}</Description>
          </Grid>
        </Grid>
      }
    />
  );
}
