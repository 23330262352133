export interface ISeller {
  id: number,
  name: string,
  site?: string,
  token: string,
  documentation?: {
    offer: string,
    policy: string
  }
}

export enum SellerActionsTypes {
  FETCH_SELLER = "FETCH_SELLER",
  UPDATE_SELLER = "UPDATE_SELLER",
  DOCUMENTATION_SELLER = "DOCUMENTATION_SELLER",
  CLEAR_SELLER = "CLEAR_SELLER"
}