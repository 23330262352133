import {MerchantServices} from "../services/merchant";
import {Dispatch} from "../../App/reducers/store";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {IMerchant} from "../interfaces/merchant";

const all = () => {
  return (dispatch: Dispatch): Promise<Array<IMerchant>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return MerchantServices.all()
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

export const MerchantActions = {
  all
}