import React, {ReactElement} from 'react';
import {
  Control,
  Controller,
  useWatch
} from "react-hook-form";
import {Grid} from "@mui/material";
import {IMerchant as IPaymentMerchant} from "../../../Payment/interfaces/merchant";
import {HiddenField} from "../../../App/components/Input/HiddenField";

interface Props {
  control: Control,
  disabled: boolean
}

export function Params(props: Props): ReactElement | null {
  const {control, disabled} = props;

  const merchant: IPaymentMerchant = useWatch({
    control,
    name: 'merchant'
  })

  return merchant ? (
    <React.Fragment>
      {Object.entries(merchant.attributes).map(([name, param], i) => (
        <React.Fragment key={`${merchant.id}.${i}`}>
          {param.hasOwnProperty('name') ? (
            <Grid item xs={12} sx={{height: '100%', minHeight: 80}}>
              <Controller
                name={`params.${name}`}
                control={control}
                render={({
                  field: {onChange, value}, fieldState
                }) => (
                  <HiddenField
                      fullWidth
                      required
                      disabled={disabled}
                      label={`${param.name}:`}
                      error={!!fieldState.error}
                      onChange={onChange}
                      value={value}
                      helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          ) : Object.entries(param).map(([key, option], j) => (
            option instanceof Object ? (
              <Grid item key={`${merchant.id}.${i}.${j}`} xs={12} sx={{height: '100%', minHeight: 80}}>
                <Controller
                  name={`params.${name}.${key}`}
                  control={control}
                  render={({
                    field: {onChange, value}, fieldState
                  }) => (
                    <HiddenField
                      fullWidth
                      required
                      disabled={disabled}
                      label={`${option.name}:`}
                      error={!!fieldState.error}
                      onChange={onChange}
                      value={value}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            ) : null
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  ) : null
}