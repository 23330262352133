import {HttpService} from "../../App/services/http";

const all = () => {
  return HttpService.get(`/payment/merchants`)
    .then(response => {
      const {data} = response
      return data
    })
}

export const MerchantServices = {
  all
}