const type: { [key: string]: { key: string, name: string } } = {
  custom: {
    key: 'custom',
    name: 'Индивидуальный интернет-магазин'
  },
  digiseller: {
    key: 'digiseller',
    name: 'Digiseller'
  }
}

export {type}