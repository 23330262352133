import React, {ReactElement, useEffect} from "react"
import {Outlet, useLocation} from "react-router-dom";
import {Box, Container as MUIContainer, CssBaseline, styled} from "@mui/material";
import {Header} from "../Header";
import {Permanent as Drawer} from "../Drawer/Permanent";
import {useAppSelector} from "../../hooks/store";
import {NavigateFunction, useNavigate} from "react-router";

const Background = styled(Box)(({theme}) => ({
  backgroundColor: 'rgb(249, 250, 251)',
  display: 'flex'
}));

const Content = styled(Box)(({theme}) => ({
  height: '100%',
  minHeight: 'calc(100vh - 80px)',
  marginTop: '80px',
  backgroundColor: 'rgb(249, 250, 251)',
  flexGrow: 1
}));

const Container = styled(MUIContainer)(({theme}) => ({
  padding: '24px'
}));

export function Application(): ReactElement | null {
  const location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const {account} = useAppSelector(state => state.account)

  useEffect(() => {
    if (account.sellers.length) {
      switch (location.pathname) {
        case '/':
          navigate(`/seller/${account.sellers![0].token}`)
      }
    }
  }, []);

  return (
    <Background>
      <CssBaseline/>
      <Header/>
      <Drawer/>
      <Content>
        <Container maxWidth="xl">
          <Outlet/>
        </Container>
      </Content>
    </Background>
  )
}