import {ReactElement} from 'react';
import {Grid, Box, Container, Paper as MUIPaper, Stack, Typography, styled} from "@mui/material";

const Background = styled(Box)(({theme}) => ({
  backgroundColor: 'rgb(243, 244, 246)'
}));

const Code = styled(Typography)(({theme}) => ({
  fontWeight: 600,
  fontSize: '20px',
  fontFeatureSettings: 'normal',
  fontVariationSettings: 'normal',
  lineHeight: '28px',
  color: 'rgb(17, 24, 39)'
}));

const Message = styled(Typography)(({theme}) => ({
  marginTop: '8px',
  fontSize: '14px',
  fontFeatureSettings: 'normal',
  fontVariationSettings: 'normal',
  lineHeight: '22.75px',
  color: 'rgb(156, 163, 175)'
}));

const Paper = styled(MUIPaper)(({theme}) => ({
  backgroundColor: 'rgb(255, 255, 255)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  overflow: 'hidden',
  boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(107, 114, 128, 0.2) 0px 25px 50px -12px',
  borderRadius: '0.5rem',
  padding: '16px 48px',
}));

interface Props {
  code: number
  message: string
}

export function Status(props: Props): ReactElement | null {
  const {code, message} = props

  return (
    <Background>
      <Container>
        <Stack height="100vh" direction="column" alignItems="center" justifyContent="center">
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={9}>
              <Paper>
                <Code>{code}</Code>
                <Message>{message}</Message>
              </Paper>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Background>
  )
}