import {ReactElement, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {
  Grid, Box, Typography,
  IconButton, MenuItem,
  Stack,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {useAppSelector} from "../App/hooks/store";
import {ContentCopy as ContentCopyIcon} from "@mui/icons-material";
import {Controller, DefaultValues, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {Redirect} from "./components/Redirect";
import {ISettingsInput} from "./interfaces/inputs/settings.inputs.interface";
import {SellerActions} from "./actions/seller";
import {Button} from "../App/components/Input/Button";
import {TextField} from "../App/components/Input/TextField";
import {Title} from "../App/components/Typography/Title";
import {Content} from "../App/components/Papper/Content";
import {Chip} from "../App/components/Chip";
import {type} from "./constants/type";
import {Notifications} from "./components/Notifications";
import {Documentation} from "./components/Documentation";

export function Settings(): ReactElement | null {
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const {seller} = useAppSelector(state => state.seller)

  const schema = Yup
    .object({
      name: Yup.string().required("Введите наименование"),
      site: Yup.string().nullable().url(),
      type: Yup.string().required("Выберите тип"),
      params: (seller.type.key === type.digiseller.key) ? Yup.object({
        id: Yup.number().nullable().required("Введите идентификатор"),
        secret: Yup.string().required("Введите секретный ключ"),
        token: Yup.string().required("Введите API ключ"),
      }) : Yup.object({
        secret: Yup.string().required("Введите секретный ключ")
      }),
      redirect: Yup.object({
        success: Yup.object({
          method: Yup.string().when('url', {
            is: (field: string) => !!field?.length,
            then: (schema) => schema.required(" "),
          }),
          params: Yup.array().of(Yup.object().shape({
            name: Yup.string().required(" "),
            value: Yup.string().required(" ")
          })),
          url: Yup.string()
        }),
        fail: Yup.object({
          method: Yup.string().when('url', {
            is: (field: string) => !!field?.length,
            then: (schema) => schema.required(" "),
          }),
          params: Yup.array().of(Yup.object().shape({
            name: Yup.string().required(" "),
            value: Yup.string().required(" ")
          })),
          url: Yup.string()
        })
      }),
      webhook: Yup.object().shape({
        method: Yup.string().when('url', {
          is: (field: string) => !!field?.length,
          then: (schema) => schema.required(" "),
        }),
        url: Yup.string().when('method', {
          is: (field: string) => !!field?.length,
          then: (schema) => schema.required("Введите адрес"),
        }),
        params: Yup.object().shape({
          type: Yup.string().nullable()
        })
      }, [['url', 'method']])
    }).required()

  const values: DefaultValues<any> = {
    name: seller.name,
    site: seller.site ?? '',
    type: seller.type.key,
    params: {
      id: seller.params?.id ?? null,
      secret: seller.params?.secret ?? '',
      token: seller.params?.token ?? ''
    },
    redirect: {
      success: seller.redirect?.success ? {
        method: 'GET',
        params: seller.redirect.success.params ?? [],
        url: seller.redirect.success.url ?? ''
      } : {
        method: 'GET',
        params: [],
        url: ''
      },
      fail: seller.redirect?.fail ? {
        method: 'GET',
        params: seller.redirect.fail.params ?? [],
        url: seller.redirect.fail.url ?? ''
      } : {
        method: 'GET',
        params: [],
        url: ''
      }
    },
    webhook: {
      method: seller.webhook?.method ?? '',
      url: seller.webhook?.url ?? '',
      params: seller.webhook?.params ? {
        type: seller.webhook?.params?.type ?? ''
      } : {
        type: ''
      }
    }
  }

  const {formState: {isSubmitSuccessful}, control, handleSubmit, reset, setError, setValue} = useForm({
    defaultValues: values,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset(values)
  }, [seller])

  const onSubmit: SubmitHandler<ISettingsInput> = (data) => {
    dispatch(SellerActions.update(seller.token, data)).then(() => {
      reset(data)
    }).catch((error: { errors: object }) => {
      reset(data)
      if (error.hasOwnProperty("errors")) {
        Object.entries(error.errors).forEach(([name, message]) => {
          setError(name as keyof object, {type: "manual", message: message as string})
        })
      }
    })
  }

  return (
    <Stack spacing={2}>
      <Grid item>
        <Title>
          Настройки
        </Title>
      </Grid>
      <Grid item>
        <Grid container direction={mobile ? "column" : "row" as 'column' | "row"} spacing={2}>
          <Grid item xs={6}>
            <Grid container direction="column" spacing={2}>
              <Grid sx={{ width: '100%' }} item xs={12}>
                <Content
                  sx={{
                    padding: '24px'
                  }}
                >
                  <Grid container direction="column" spacing={2}>
                    {[
                      {
                        name: 'Токен',
                        value: seller.token,
                        action: null,
                        method: null
                      },
                      {
                        name: 'Инициализация платежа',
                        value: `${process.env.REACT_APP_HOST}/payment/${seller.token}`,
                        method: 'POST'
                      },
                      {
                        name: 'Выставление счета',
                        value: `${process.env.REACT_APP_HOST}/payment/${seller.token}/invoice`,
                        method: 'POST'
                      },
                      {
                        name: 'Статус платежа',
                        value: `${process.env.REACT_APP_HOST}/api/payment/status/${seller.token}`,
                        method: 'GET'
                      },
                    ].map((item, index) => (
                      <Grid sx={{ width: '100%' }} item key={index}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Grid item xs={6} sm={7} xl={8}>
                            <Typography
                              noWrap
                              sx={{
                                fontWeight: 500,
                                lineHeight: 1.57143,
                                fontSize: '0.875rem'
                              }}
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              noWrap
                              sx={{
                                lineHeight: 1.57143,
                                fontSize: '0.875rem',
                                fontWeight: 400,
                                color: 'rgb(99, 115, 129)'
                              }}
                            >
                              {item.value}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={5} xl={4}>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                              {item.method ? (
                                <Grid item xs={5} sm={4} md={5}>
                                  <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Chip label={item.method}/>
                                  </Grid>
                                </Grid>
                              ) : null}
                              <Grid item>
                                <IconButton
                                  size="small"
                                  onClick={async () => {
                                    await navigator.clipboard.writeText(item.value)
                                  }}
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Content>
              </Grid>
              <Notifications />
              <Documentation />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Content
              sx={{
                padding: '24px'
              }}
            >
              <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
                <Grid container direction="column" justifyContent="center" alignItems="stretch">
                  <Grid item sx={{height: '100%', minHeight: 80}}>
                    <Controller
                      name="name"
                      control={control}
                      render={({
                        field: {onChange, value}, fieldState
                      }) => (
                        <TextField
                          required
                          disabled={isSubmitSuccessful}
                          label="Наименование:"
                          error={!!fieldState.error}
                          onChange={onChange}
                          value={value}
                          helperText={fieldState.error?.message}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sx={{height: '100%', minHeight: 80}}>
                    <Controller
                      name="site"
                      control={control}
                      render={({
                        field: {onChange, value}, fieldState
                      }) => (
                        <TextField
                          disabled={isSubmitSuccessful}
                          label="Сайт:"
                          error={!!fieldState.error}
                          onChange={onChange}
                          value={value}
                          helperText={fieldState.error?.message}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sx={{height: '100%', minHeight: 80}}>
                    <Controller
                      name="params.secret"
                      control={control}
                      render={({
                        field: {onChange, value}, fieldState
                      }) => (
                        <TextField
                          required
                          disabled={isSubmitSuccessful}
                          label="Секретный ключ:"
                          error={!!fieldState.error}
                          onChange={onChange}
                          value={value}
                          helperText={fieldState.error?.message}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  {(seller.type.key === type.digiseller.key) ? (
                    <Grid item sx={{height: '100%', minHeight: 80}}>
                      <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={2}>
                        <Grid item xs={4}>
                          <Controller
                            name="params.id"
                            control={control}
                            render={({
                              field: {onChange, value}, fieldState
                            }) => (
                              <TextField
                                required
                                type="number"
                                disabled={isSubmitSuccessful}
                                label="Идентификатор:"
                                error={!!fieldState.error}
                                onChange={(e) => {
                                  onChange(e.target.value ? Number(e.target.value) : null)
                                }}
                                value={value ?? ''}
                                helperText={fieldState.error?.message}
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Controller
                            name="params.token"
                            control={control}
                            render={({
                              field: {onChange, value}, fieldState
                            }) => (
                              <TextField
                                required
                                disabled={isSubmitSuccessful}
                                label="API ключ:"
                                error={!!fieldState.error}
                                onChange={onChange}
                                value={value}
                                helperText={fieldState.error?.message}
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid item sx={{height: '100%', minHeight: 80}}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                      <Grid item xs={3}>
                        <Controller
                          name="webhook.method"
                          control={control}
                          render={({
                            field: {onChange, value}, fieldState
                          }) => (
                            <TextField
                              select
                              disabled={isSubmitSuccessful}
                              label="Метод:"
                              error={!!fieldState.error}
                              onChange={onChange}
                              value={value}
                              fullWidth
                            >
                              {[
                                {label: 'GET', value: 'GET'},
                                {label: 'POST', value: 'POST'},
                                {label: 'PUT', value: 'PUT'},
                              ].map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Controller
                          name="webhook.url"
                          control={control}
                          render={({
                            field: {onChange, value}, fieldState
                          }) => (
                            <TextField
                              disabled={isSubmitSuccessful}
                              label="Оповещение об успешной оплате:"
                              error={!!fieldState.error}
                              onChange={(event) => {
                                const value = event.target.value
                                setValue('webhook.url', value)
                                if (!value.length) {
                                  setValue('webhook.method', '')
                                }
                              }}
                              value={value}
                              helperText={fieldState.error?.message}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{height: '100%', minHeight: 80}}>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                      <Grid item xs={9}>
                        <Controller
                          name="webhook.params.type"
                          control={control}
                          render={({
                            field: {onChange, value}, fieldState
                          }) => (
                            <TextField
                              select
                              disabled={isSubmitSuccessful}
                              label="Тип контента:"
                              error={!!fieldState.error}
                              onChange={onChange}
                              value={value}
                              fullWidth
                            >
                              {[
                                {label: 'application/x-www-form-urlencoded', value: 'application/x-www-form-urlencoded'},
                                {label: 'application/json', value: 'application/json'}
                              ].map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Redirect
                    control={control}
                    name={'redirect.success'}
                    label={"Переадресация после успешной оплата:"}
                    disabled={isSubmitSuccessful}
                  />
                  <Redirect
                    control={control}
                    name={'redirect.fail'}
                    label={"Переадресация в случае неудачной оплата:"}
                    disabled={isSubmitSuccessful}
                  />
                  <Grid item>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                        >
                          Сохранить
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Content>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  )
}