import {AccountServices} from "../services/account";
import {AccountActionsTypes} from "../interfaces/account";
import {LoadingActionsTypes} from "../../App/interfaces/loading";

const account = () => {
  return (dispatch: any) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return AccountServices.account()
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({type: AccountActionsTypes.FETCH_ACCOUNT, payload: response})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

export const AccountActions = {
  account
}