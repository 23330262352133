import React, {ReactElement, useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  IconButton,
  styled,
  Accordion as MUIAccordion,
  AccordionSummary as MUIAccordionSummary,
  AccordionDetails as MUIAccordionDetails,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl as MUIFormControl, useTheme, useMediaQuery,
} from "@mui/material";
import {INotification} from "../../interfaces/notification";
import {KeyboardArrowDownOutlined} from "@mui/icons-material";
import * as Yup from "yup";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useAppSelector} from "../../../App/hooks/store";
import {yupResolver} from "@hookform/resolvers/yup";
import {INotificationInput} from "../../interfaces/inputs/notification.inputs.interface";
import {useDispatch} from "react-redux";
import {NotificationActions} from "../../actions/notification";
import {Button} from "../../../App/components/Input/Button";
import {TextField} from "../../../App/components/Input/TextField";

const Accordion = styled(MUIAccordion)(({theme}) => ({
  width: '100%',
  boxShadow: 'none',
  '& .MuiButtonBase-root': {
    '&:hover:not(.MuiIconButton-root):not(.MuiButton-root):not(.MuiCheckbox-root)': {
      cursor: 'default'
    }
  }
}));

const AccordionSummary = styled(MUIAccordionSummary)(({theme}) => ({
  minHeight: 'auto',
  padding: 'unset',
  '& .MuiAccordionSummary-content': {
    margin: 'unset'
  },
  '&.Mui-expanded': {
    minHeight: 'auto',
    margin: 'unset'
  }
}));

const AccordionDetails = styled(MUIAccordionDetails)(({theme}) => ({
  padding: '16px 0 0 0',
  cursor: 'auto',
  '&:hover': {
    cursor: 'auto'
  }
}));

const FormControl = styled(MUIFormControl)(({theme}) => ({
  '&:hover': {
    borderRadius: '6px',
    backgroundColor: 'rgba(145, 158, 171, 0.08)'
  },
  '& .MuiFormControlLabel-root': {
    height: '50.125px',
    marginLeft: '0',
    '& .MuiButtonBase-root': {
      padding: '8px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        borderRadius: '6px'
      }
    },
    '& .MuiTypography-root': {
      fontWeight: '400',
      fontSize: "0.875rem",
      color: "rgb(145, 158, 171)",
      whiteSpace: "pre-wrap"
    }
  }
}))

interface Props {
  item: INotification
}

export function Notification(props: Props): ReactElement | null {
  const dispatch: any = useDispatch();
  const {item} = props
  const theme = useTheme();
  const {seller} = useAppSelector(state => state.seller)
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [expanded, setExpanded] = useState(false)

  const schema = Yup
    .object({
      seller: Yup.string().required('Выберите продавца'),
      events: Yup.object().shape({
        gateway_not_available: Yup.boolean().required(),
        merchant_not_available: Yup.boolean().required(),
        merchant_error: Yup.boolean().required(),
        invoice_wait: Yup.boolean().required(),
      }),
      settings: Yup.object().shape({
        invoice_wait: Yup.object().shape({
          delay: Yup.number().min(1).max(20).required()
        })
      })
    })

  const {formState: {isSubmitSuccessful}, control, handleSubmit, reset, setError} = useForm({
    defaultValues: {
      seller: seller.token,
      events: {
        gateway_not_available: false,
        merchant_not_available: false,
        merchant_error: false,
        invoice_wait: false,
        ...item.options?.events
      },
      settings: {
        invoice_wait: {
          delay: 5
        },
        ...item.options?.settings
      }
    },
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<INotificationInput> = (data) => {
    dispatch(NotificationActions.update(item.id, data)).then(() => {
      reset(data)
    }).catch((error: { errors: object }) => {
      reset(data)
      if (error.hasOwnProperty("errors")) {
        Object.entries(error.errors).forEach(([name, message]) => {
          setError(name as keyof object, {type: "manual", message: message as string})
        })
      }
    })
  }

  useEffect(() => {
    reset({
      seller: seller.token,
      events: {
        gateway_not_available: false,
        merchant_not_available: false,
        merchant_error: false,
        invoice_wait: false,
        ...item.options?.events
      },
      settings: {
        invoice_wait: {
          delay: 5
        },
        ...item.options?.settings
      }
    })
  }, [item])

  return (
    <Accordion
      disableGutters
      expanded={expanded}
    >
      <AccordionSummary>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item xs={10}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  sx={{
                    fontWeight: 400,
                    lineHeight: 1.57143,
                    fontSize: '0.75rem',
                    color: 'rgb(99, 115, 129)'
                  }}
                >
                  Способ уведомления
                </Typography>
                <Typography
                  noWrap
                  sx={{
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    lineHeight: 1.43
                  }}
                >
                  {item.bot.type.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  noWrap
                  sx={{
                    fontWeight: 400,
                    lineHeight: 1.57143,
                    fontSize: '0.75rem',
                    color: 'rgb(99, 115, 129)'
                  }}
                >
                  Канал
                </Typography>
                <Typography
                  noWrap
                  sx={{
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    lineHeight: 1.43
                  }}
                >
                  {item.title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => setExpanded(!expanded)}
            >
              <KeyboardArrowDownOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container direction="column" alignItems="stretch" justifyContent="center">
            <Grid item>
              <Grid container direction="row" alignItems="stretch" justifyContent="flex-start" spacing={1}>
                <Grid item xs={mobile ? 12 : 8}>
                  <Controller
                    name="events.gateway_not_available"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <React.Fragment>
                        <FormControl
                          required
                          fullWidth
                          disabled={false}
                          variant="standard"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!value}
                                onChange={onChange}
                              />
                            }
                            label={'Способ оплаты не доступен'}
                          />
                        </FormControl>
                      </React.Fragment>
                    )}
                  />
                </Grid>
                <Grid item xs={mobile ? 12 : 8}>
                  <Controller
                    name="events.merchant_not_available"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <React.Fragment>
                        <FormControl
                          required
                          fullWidth
                          disabled={isSubmitSuccessful}
                          variant="standard"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!value}
                                onChange={onChange}
                              />
                            }
                            label={'Платежная система недоступна'}
                          />
                        </FormControl>
                      </React.Fragment>
                    )}
                  />
                </Grid>
                <Grid item xs={mobile ? 12 : 8}>
                  <Controller
                    name="events.merchant_error"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <React.Fragment>
                        <FormControl
                          required
                          fullWidth
                          disabled={isSubmitSuccessful}
                          variant="standard"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!value}
                                onChange={onChange}
                              />
                            }
                            label={'Ошибка платежной системы'}
                          />
                        </FormControl>
                      </React.Fragment>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" alignItems="flex-start" justifyContent="space-between" spacing={1}>
                    <Grid item xs={mobile ? 12 : 8}>
                      <Controller
                        name="events.invoice_wait"
                        control={control}
                        render={({
                          field: {onChange, value}, fieldState
                        }) => (
                          <FormControl
                            required
                            fullWidth
                            disabled={isSubmitSuccessful}
                            variant="standard"
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!value}
                                  onChange={onChange}
                                />
                              }
                              label={'Платеж в обработке'}
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={mobile ? 12 : 4} sx={{minHeight: 80}}>
                      <Controller
                        name="settings.invoice_wait.delay"
                        control={control}
                        render={({
                          field: {onChange, value}, fieldState
                        }) => (
                          <TextField
                            required
                            type="number"
                            disabled={isSubmitSuccessful}
                            label="Продолжительность:"
                            error={!!fieldState.error}
                            onChange={onChange}
                            InputProps={{
                              inputProps: { min: 1, max: 20 }
                            }}
                            value={value}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}