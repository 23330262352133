import {SellerServices} from "../services/seller";
import {SellerActionsTypes} from "../interfaces/seller";
import {Dispatch} from "../../App/reducers/store";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {ISettingsInput} from "../interfaces/inputs/settings.inputs.interface";
import {AlertActionsTypes} from "../../App/interfaces/alert";
import {AccountActionsTypes} from "../../Account/interfaces/account";
import {type} from "../constants/type";
import {IDocumentationInput} from "../interfaces/inputs/documentation.inputs.interface";

const info = (token: string) => {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return SellerServices.info(token)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const seller = (token: string) => {
  return (dispatch: Dispatch) => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.LOADING, payload: true})
    return SellerServices.seller(token)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          dispatch({type: SellerActionsTypes.FETCH_SELLER, payload: response})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.LOADING, payload: false})
          reject(error)
        }
      )
  })
}

const update = (token: string, data: ISettingsInput) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return SellerServices.update(token, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          dispatch({
            type: AccountActionsTypes.UPDATE_ACCOUNT_SELLER,
            payload: {
              token: token,
              ...data,
              type: type[data.type]
            }
          })
          dispatch({
            type: SellerActionsTypes.UPDATE_SELLER,
            payload: {
              ...data,
              type: type[data.type]
            }
          })
          dispatch({
            type: AlertActionsTypes.SUCCESS,
            payload: {
              type: "success",
              message: 'Данные обновлены',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const documentation = (token: string, data: IDocumentationInput) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return SellerServices.documentation(token, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          dispatch({
            type: SellerActionsTypes.DOCUMENTATION_SELLER,
            payload: {
              ...data
            }
          })
          dispatch({
            type: AlertActionsTypes.SUCCESS,
            payload: {
              type: "success",
              message: 'Данные обновлены',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

export const SellerActions = {
  info,
  seller,
  update,
  documentation
}