import React, {ReactElement} from 'react';
import {useDispatch} from "react-redux";
import {
  Grid, Box,
  IconButton,
  useMediaQuery,
  useTheme, MenuItem
} from "@mui/material";
import {useAppSelector} from "../../../App/hooks/store";
import {
  Controller,
  DefaultValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {IGateway} from "../../../Payment/interfaces/gateway";
import {Close as CloseIcon} from "@mui/icons-material";
import {Backdrop} from "../../../App/components/Backdrop";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "../../../App/components/Dialog";
import {Button} from "../../../App/components/Input/Button";
import {TextField} from "../../../App/components/Input/TextField";
import {InvoiceActions} from "../../../Payment/actions/invoice";
import {IInvoiceInput} from "../../../Payment/interfaces/inputs/invoice.inputs.interface";
import {ICurrency} from "../../../Payment/interfaces/currency";

interface Props {
  gateway: IGateway,
  open: boolean,
  onClose: () => void
}

export function Invoice(props: Props): ReactElement | null {
  const dispatch: any = useDispatch();
  const {gateway, open, onClose} = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {account, seller} = useAppSelector(state => ({...state.account, ...state.seller}))

  const schema = Yup
    .object({
      gateway: Yup.number().required('Выберите способ оплаты'),
      number: Yup.string().required("Введите номер заказа"),
      amount: Yup.number().required('Введите сумму'),
      currency: Yup.string().required('Выберите валюту'),
      description: Yup.string().required('Укажите описание'),
      email: Yup.string().email('Некорректный формат электронной почты').required("Введите адрес электронной почты")
    }).required()

  const values: DefaultValues<any> = {
    gateway: gateway.number,
    number: '',
    amount: 0,
    currency: 'RUB',
    description: '',
    email: account?.email ?? ''
  }

  const {formState: {isSubmitSuccessful}, control, handleSubmit, reset, setError, setValue} = useForm({
    defaultValues: values,
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<IInvoiceInput> = (data) => {
    dispatch(InvoiceActions.create(seller.token, data)).then((response: { link: string } | void) => {
      reset(data)
      if (response) {
        const tab = window.open(response.link, '_blank', 'noopener,noreferrer')
        if (tab) tab.opener = null
        onClose()
      }
    }).catch((error: { errors: object }) => {
      reset(data)
      if (error.hasOwnProperty("errors")) {
        Object.entries(error.errors).forEach(([name, message]) => {
          setError(name as keyof object, {type: "manual", message: message as string})
        })
      }
    })
  }

  return (
    <Dialog
      open={open}
      fullWidth
      fullScreen={mobile}
      onClose={onClose}
      maxWidth="sm"
      slots={{
        backdrop: Backdrop
      }}
    >
      <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              Платеж
            </Grid>
            <Grid item>
              <IconButton
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" alignItems="stretch" justifyContent="center">
            <Grid item sx={{height: '80px'}}>
              <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="number"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <TextField
                        required
                        disabled={isSubmitSuccessful}
                        label="Номер заказа:"
                        error={!!fieldState.error}
                        onChange={onChange}
                        value={value}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="gateway"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <TextField
                        select
                        required
                        disabled
                        label="Способ оплаты:"
                        error={!!fieldState.error}
                        onChange={onChange}
                        value={value}
                        fullWidth
                      >
                        {[
                          {label: gateway.method.name, value: gateway.number},
                        ].map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{height: '80px'}}>
              <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="amount"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <TextField
                        required
                        type="number"
                        disabled={isSubmitSuccessful}
                        label="Сумма:"
                        error={!!fieldState.error}
                        onChange={onChange}
                        value={value}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="currency"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <TextField
                        select
                        required
                        label="Валюта:"
                        error={!!fieldState.error}
                        onChange={onChange}
                        value={value}
                        fullWidth
                      >
                        {gateway.merchants.reduce((currencies, merchant) => {
                          return currencies.concat(merchant.merchant.currencies.filter(currency =>
                            !currencies.some(item => item.id === currency.id)
                          ))
                        }, [] as Array<ICurrency>).map((option: ICurrency) => (
                          <MenuItem key={option.id} value={option.code}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{height: '80px'}}>
              <Controller
                name="description"
                control={control}
                render={({
                  field: {onChange, value}, fieldState
                }) => (
                  <TextField
                    required
                    disabled={isSubmitSuccessful}
                    label="Описание:"
                    error={!!fieldState.error}
                    onChange={onChange}
                    value={value}
                    helperText={fieldState.error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item sx={{height: '80px'}}>
              <Controller
                name="email"
                control={control}
                render={({
                  field: {onChange, value}, fieldState
                }) => (
                  <TextField
                    required
                    disabled={isSubmitSuccessful}
                    label="Электронная почта:"
                    error={!!fieldState.error}
                    onChange={onChange}
                    value={value}
                    helperText={fieldState.error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
          >
            Оплатить
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}