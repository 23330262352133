import {useEffect, useState} from 'react';
import {useAppSelector} from "./store";

export const useFilling = (): [boolean, () => void] => {
  const {filling} = useAppSelector(state => state.loading)
  const [isFilling, setIsFilling] = useState(true);

  const toggle = () => {
    setIsFilling(!isFilling);
  }

  useEffect(() => {
    setIsFilling(filling);
  }, [filling]);

  return [
    isFilling,
    toggle
  ];
}