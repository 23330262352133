import {HttpService} from "../../App/services/http";

const account = () => {
  return HttpService.get("/account")
    .then(response => {
      const {data} = response
      return data
    })
}

export const AccountServices = {
  account
}