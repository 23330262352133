import React, {ReactElement} from "react"
import {DeleteOutline as DeleteIcon} from "@mui/icons-material";
import {Grid, ListItemIcon, ListItemText} from "@mui/material";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../../App/hooks/store";
import {IGateway} from "../../../../../Payment/interfaces/gateway";
import {GatewayActions} from "../../../../actions/gateway";
import {ActionActionsTypes} from "../../../../../App/interfaces/action";
import {MenuItem} from "../../../../../App/components/Menu";

interface Props {
  gateway: IGateway,
  onChange?: (gateway: IGateway) => void
}

export function Delete(props: Props): ReactElement | null {
  const dispatch: any = useDispatch();
  const {seller} = useAppSelector(state => state.seller)
  const {gateway, onChange} = props

  return (
    <Grid item>
      <MenuItem
        onClick={() => {
          dispatch({type: ActionActionsTypes.CREATE, payload: {
              message: 'Подтвердите действие',
              action: async () => {
                await dispatch(GatewayActions.delete(gateway.id, seller)).then(() => {
                  if (onChange) {
                    onChange(gateway)
                  }
                })
              }}
          })
        }}
      >
        <ListItemIcon>
          <DeleteIcon color="error" fontSize="small"/>
        </ListItemIcon>
        <ListItemText>Удалить</ListItemText>
      </MenuItem>
    </Grid>
  )
}
