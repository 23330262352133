import React, {ReactElement, useState} from "react"
import {IColumn} from "../../interfaces/Table/column";
import Grid from "@mui/material/Grid";
import {IFilter} from "../../interfaces/Table/filter";
import {TextField} from "../Input/TextField";

interface Props {
  column: IColumn,
  filter: IFilter,
  setFilter: (filter: IFilter) => void
}

export function Interval(props: Props): ReactElement {
  const {column, filter, setFilter} = props
  const [intervals, setIntervals] = useState<{start: number | undefined, end: number | undefined}>(filter.intervals?.[column.key] ?? { start: undefined, end: undefined })

  return (
    <Grid item>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            sx={{
              '& .MuiInputBase-root': {
                padding: '0',
                maxHeight: '34px',
                '&.MuiAutocomplete-inputRoot': {
                  padding: '0 4px',
                }
              },
              '& .MuiInputBase-input': {
                padding: '8px',
                fontSize: "0.8rem",
              },
            }}
            placeholder="от"
            type="number"
            value={intervals.start ?? ''}
            onChange={(e) => {
              const value = e.target.value ? Number(e.target.value) : undefined
              setIntervals({
                ...intervals,
                start: value
              })
            }}
            onBlur={(e) => {
              if (intervals.start !== filter.intervals?.[column.key]?.start) {
                setFilter({
                  ...filter,
                  intervals: {
                    ...filter.intervals,
                    [column.key]: intervals
                  }
                })
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            sx={{
              '& .MuiInputBase-root': {
                padding: '0',
                maxHeight: '34px',
                '&.MuiAutocomplete-inputRoot': {
                  padding: '0 4px',
                }
              },
              '& .MuiInputBase-input': {
                padding: '8px',
                fontSize: "0.8rem",
              },
            }}
            placeholder="до"
            type="number"
            value={intervals.end ?? ''}
            onChange={(e) => {
              const value = e.target.value ? Number(e.target.value) : undefined
              setIntervals({
                ...intervals,
                end: value
              })
            }}
            onBlur={(e) => {
              if (intervals.end !== filter.intervals?.[column.key]?.end) {
                setFilter({
                  ...filter,
                  intervals: {
                    ...filter.intervals,
                    [column.key]: intervals
                  }
                })
              }
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
