import React, {ReactElement} from "react"
import {
  Grid,
  FormControlLabel,
  Checkbox, FormControl as MUIFormControl,
  styled
} from "@mui/material";
import {IColumn} from "../../interfaces/Table/column";
import {IFilter} from "../../interfaces/Table/filter";

const FormControl = styled(MUIFormControl)(({theme}) => ({
  '&:hover': {
    borderRadius: '6px',
    backgroundColor: 'rgba(145, 158, 171, 0.08)'
  },
  '& .MuiFormControlLabel-root': {
    marginLeft: '0',
    '& .MuiButtonBase-root': {
      padding: '5px',
      '&:hover': {
        backgroundColor: 'transparent',
        borderRadius: '6px'
      }
    },
    '& .MuiTypography-root': {
      fontWeight: '400',
      fontSize: "0.8rem",
      color: "rgba(0, 0, 0, 0.6)",
      whiteSpace: "pre-wrap"
    }
  }
}))

interface Props {
  column: IColumn,
  filter: IFilter,
  setFilter: (filter: IFilter) => void
}

export function Options(props: Props): ReactElement {
  const {column, filter, setFilter} = props

  return (
    <Grid item>
      <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={0.5}>
        {column.filter?.options?.map((option, index) => (
          <Grid item key={index}>
            <FormControl required fullWidth variant="standard">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(filter.filters?.[column.key]?.includes(option.id))}
                    onChange={(event, checked) => {
                      let filters = filter.filters?.[column.key] ?? []

                      checked ? filters.push(option.id) : filters = filters.filter(id => id !== option.id)

                      setFilter({
                        ...filter,
                        filters: {
                          ...filter.filters,
                          [column.key]: filters
                        }
                      })
                    }}
                  />
                }
                label={option.name}
              />
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
