import React, {ReactElement, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {
  Accordion as MUIAccordion, AccordionDetails as MUIAccordionDetails, AccordionSummary as MUIAccordionSummary,
  Box, Grid, IconButton, styled, Typography,
} from "@mui/material";
import {useAppSelector} from "../../App/hooks/store";
import {Content} from "../../App/components/Papper/Content";
import {KeyboardArrowDownOutlined} from "@mui/icons-material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {TextField} from "../../App/components/Input/TextField";
import {Button} from "../../App/components/Input/Button";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {IDocumentationInput} from "../interfaces/inputs/documentation.inputs.interface";
import {SellerActions} from "../actions/seller";

export const Title = styled(Typography)(({theme}) => ({
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: 1.5
}));

const Accordion = styled(MUIAccordion)(({theme}) => ({
  width: '100%',
  boxShadow: 'none',
  '& .MuiButtonBase-root': {
    '&:hover:not(.MuiIconButton-root):not(.MuiButton-root):not(.MuiCheckbox-root)': {
      cursor: 'default'
    }
  }
}));

const AccordionSummary = styled(MUIAccordionSummary)(({theme}) => ({
  minHeight: 'auto',
  padding: 'unset',
  '& .MuiAccordionSummary-content': {
    margin: 'unset'
  },
  '&.Mui-expanded': {
    minHeight: 'auto',
    margin: 'unset'
  }
}));

const AccordionDetails = styled(MUIAccordionDetails)(({theme}) => ({
  padding: '16px 0 0 0',
  cursor: 'auto',
  '&:hover': {
    cursor: 'auto'
  }
}));

export function Documentation(): ReactElement | null {
  const dispatch: any = useDispatch();
  const {seller} = useAppSelector(state => state.seller)
  const [expanded, setExpanded] = useState(false)

  const schema = Yup
    .object({
      seller: Yup.string().required('Выберите продавца'),
      documentation: Yup.object().shape({
        offer: Yup.string().nullable().url(),
        policy: Yup.string().nullable().url(),
      })
    })

  const {formState: {isSubmitSuccessful}, control, handleSubmit, reset, setError} = useForm({
    defaultValues: {
      seller: seller.token,
      documentation: seller.documentation ?? {
        offer: '',
        policy: ''
      }
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset({
      documentation: seller.documentation ?? {
        offer: '',
        policy: ''
      }
    })
  }, [seller]);

  const onSubmit: SubmitHandler<IDocumentationInput> = (data) => {
    dispatch(SellerActions.documentation(seller.token, data)).then(() => {
      reset(data)
    }).catch((error: { errors: object }) => {
      reset(data)
      if (error.hasOwnProperty("errors")) {
        Object.entries(error.errors).forEach(([name, message]) => {
          setError(name as keyof object, {type: "manual", message: message as string})
        })
      }
    })
  }

  return (
    <Grid item>
      <Content
        sx={{
          padding: '24px'
        }}
      >
        <Grid item xs={12}>
          <Accordion
            disableGutters
            expanded={expanded}
          >
            <AccordionSummary>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Title>
                    Документация
                  </Title>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => setExpanded(!expanded)}
                  >
                    <KeyboardArrowDownOutlined />
                  </IconButton>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
                <Grid container direction="column" alignItems="stretch" justifyContent="center" spacing={2}>
                  <Grid item sx={{height: '100%', minHeight: 80}}>
                    <Controller
                      name="documentation.offer"
                      control={control}
                      render={({
                        field: {onChange, value}, fieldState
                      }) => (
                        <TextField
                          disabled={isSubmitSuccessful}
                          label="Публичная оферта:"
                          error={!!fieldState.error}
                          onChange={onChange}
                          value={value}
                          helperText={fieldState.error?.message}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sx={{height: '100%', minHeight: 80}}>
                    <Controller
                      name="documentation.policy"
                      control={control}
                      render={({
                        field: {onChange, value}, fieldState
                      }) => (
                        <TextField
                          disabled={isSubmitSuccessful}
                          label="Политика конфиденциальности:"
                          error={!!fieldState.error}
                          onChange={onChange}
                          value={value}
                          helperText={fieldState.error?.message}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                        >
                          Сохранить
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Content>
    </Grid>
  )
}