import {AnyAction, configureStore, ThunkDispatch} from "@reduxjs/toolkit";
import {Loading} from "./loading";
import {Alert} from "./alert";
import {Action} from "./action";
import {Account} from "../../Account/reducers/account";
import {Seller} from "../../Seller/reducers/seller";
import logger from "redux-logger";
import thunk from "redux-thunk";

export const store = configureStore({
  reducer: {
    loading: Loading,
    alert: Alert,
    action: Action,
    account: Account,
    seller: Seller,
  },
  middleware: [thunk, logger] as const
});

export type RootState = ReturnType<typeof store.getState>
export type Dispatch = typeof store.dispatch
export type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;