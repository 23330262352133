import React, {ReactElement} from "react";
import {Select as MUISelect, SelectProps, styled} from "@mui/material";

const StyledSelect = styled(MUISelect)(({theme}) => ({
  width: '100%',
  minHeight: '48px',
  borderRadius: '12px',
  backgroundColor: 'rgba(145, 158, 171, 0.12)',
  boxShadow: 'none',
  color: 'rgb(33, 43, 54)',
  fontWeight: 500,
  fontSize: '14px',
  textTransform: 'none',
  lineHeight: '22px',
  '&.MuiOutlinedInput-root': {
    borderColor: 'transparent',
    '& .MuiSelect-select': {
      paddingLeft: '16px',
    },
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '&.Mui-disabled': {
    color: 'rgb(33, 43, 54)',
    backgroundColor: 'rgba(145, 158, 171, 0.12)'
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'rgba(145, 158, 171, 0.12)'
  },
  '&:active': {
    boxShadow: 'none',
  },
}));

export function Select({children, ...props}: SelectProps): ReactElement {
  return <StyledSelect
    {...props}
  >
    {children}
  </StyledSelect>
}