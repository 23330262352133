import React, {ReactElement} from 'react';

import {
  Grid, Typography,
  IconButton, MenuItem,
  styled
} from "@mui/material";
import {Control, Controller, useFieldArray} from "react-hook-form";
import {Add, Clear} from "@mui/icons-material";
import {IMerchant} from "../../../interfaces/merchant";
import {TextField} from "../../../../App/components/Input/TextField";

const Label = styled(Typography)(({theme}) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  color: "rgb(145, 158, 171)",
}));

export type Props = {
  control: Control,
  index: number,
  merchant: IMerchant,
  disabled: boolean
};

export function Amount (props: Props): ReactElement | null {
  const { control, index, merchant, disabled } = props

  const {fields, append, remove} = useFieldArray({
    control,
    name: `merchants.${index}.settings.amount`
  });

  return (
    <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Label>
              Сумма платежа:
            </Label>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item>
                <IconButton
                  onClick={() => {
                    append({currency: "", start: "", end: ""})
                  }}
                >
                  <Add fontSize="small"/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {fields.map((field, position) => (
        <Grid item key={position} sx={{height: '100%', minHeight: 80}}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <IconButton
                onClick={() => {
                  remove(position)
                }}
              >
                <Clear fontSize="small" color="error"/>
              </IconButton>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name={`merchants.${index}.settings.amount.${position}.currency`}
                control={control}
                render={({
                  field: {onChange, value}, fieldState
                }) => (
                  <TextField
                    select
                    disabled={disabled}
                    label="Валюта:"
                    error={!!fieldState.error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                  >
                    {merchant.merchant.currencies.map(option => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`merchants.${index}.settings.amount.${position}.start`}
                control={control}
                render={({
                  field: {onChange, value}, fieldState
                }) => (
                  <TextField
                    disabled={disabled}
                    placeholder="от"
                    type="number"
                    error={!!fieldState.error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`merchants.${index}.settings.amount.${position}.end`}
                control={control}
                render={({
                  field: {onChange, value}, fieldState
                }) => (
                  <TextField
                    disabled={disabled}
                    placeholder="до"
                    type="number"
                    error={!!fieldState.error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
