import React, {ReactElement, useEffect, useRef} from "react"
import Grid from "@mui/material/Grid";

interface Props {
  onCallback: () => void
}

export function Point(props: Props): ReactElement {
  const { onCallback } = props;
  const point = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries.find(entry => entry.isIntersecting)) {
          onCallback()
        }
      },
      {threshold: 0.75}
    );

    if (point.current) {
      observer.observe(point.current!);
    }

    return () => {
      if (point.current) {
        observer.unobserve(point.current!);
      }
    }
  }, [point]);

  return (
    <Grid item ref={point}></Grid>
  );
}
