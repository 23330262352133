import React, {ReactElement} from 'react';

import {
  Grid, Typography,
  IconButton,
  styled,
} from "@mui/material";
import {Control, useFieldArray} from "react-hook-form";
import {Add, Clear} from "@mui/icons-material";
import {Items} from "./Product/Items";

const Label = styled(Typography)(({theme}) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  color: "rgb(145, 158, 171)",
}));

export type Props = {
  control: Control,
  index: number,
  disabled: boolean
};

export function Product (props: Props): ReactElement | null {
  const { control, index, disabled } = props

  const {fields, append, remove} = useFieldArray({
    control,
    name: `merchants.${index}.settings.product`
  });

  return (
    <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Label>
              Товар:
            </Label>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item>
                <IconButton
                  onClick={() => {
                    append({type: "", items: []})
                  }}
                >
                  <Add fontSize="small"/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {fields.map((field, position) => (
        <Grid item key={position} sx={{height: '100%', minHeight: 80}}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <IconButton
                onClick={() => {
                  remove(position)
                }}
              >
                <Clear fontSize="small" color="error"/>
              </IconButton>
            </Grid>
            <Items control={control} index={index} position={position} disabled={disabled} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
