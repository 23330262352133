import {ReactElement} from "react";

import {
  Drawer as MUIDrawer,
  useMediaQuery, useTheme,
  styled
} from '@mui/material';
import {useAppSelector} from "../../hooks/store";
import {Content} from "./Content";

const Drawer = styled(MUIDrawer)(({theme}) => ({
  width: 279,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 279,
    boxSizing: 'border-box',
  },
  '& .MuiPaper-root ': {
    backgroundColor: 'rgb(249, 250, 251)',
    borderRightColor: 'rgba(145, 158, 171, 0.2)',
    borderRightStyle: 'dashed',
    borderRightWidth: '1px'
  },
}))

export function Permanent(): ReactElement | null {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  const {account} = useAppSelector(state => state.account)

  return (account && desktop) ? (
    <Drawer
      variant="permanent"
      anchor="left"
    >
      <Content/>
    </Drawer>
  ) : null;
}
