import {Button as MUIButton, styled} from "@mui/material";

export const Button = styled(MUIButton)(({theme}) => ({
  minHeight: '36px',
  borderRadius: '8px',
  backgroundColor: 'rgb(33, 43, 54)',
  boxShadow: 'none',
  textTransform: 'none',
  '&.MuiButton-outlined': {
    backgroundColor: 'white',
    borderColor: 'rgb(33, 43, 54)',
    color: 'rgb(33, 43, 54)',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'white'
    },
  },
  '&.Mui-disabled': {
    color: 'rgb(33, 43, 54)',
    backgroundColor: 'rgb(156 163 175)'
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: 'rgb(33, 43, 54, 0.9)'
  },
  '&:active': {
    boxShadow: 'none',
  },
}));