import React, {ReactElement, useState} from "react"
import {
  Grid,
  Fade,
  IconButton,
  MenuList
} from "@mui/material";
import {MoreVert as MoreVertIcon} from "@mui/icons-material";
import {Confirm} from "./Actions/Confirm"
import {IInvoice} from "../../interfaces/invoice";
import {Notify} from "./Actions/Notify";
import {Menu} from "../../../App/components/Menu";

interface Props {
  invoice: IInvoice,
  onChange?: (invoice: IInvoice) => void
}

export function Actions(props: Props): ReactElement | null {
  const {invoice, onChange} = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <IconButton
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="small"/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClick={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Fade}
      >
        <MenuList dense>
          <Confirm
            invoice={invoice}
            onChange={(invoice) => {
              if (onChange) {
                onChange(invoice)
              }
            }}
          />
          <Notify
            invoice={invoice}
          />
        </MenuList>
      </Menu>
    </Grid>
  )
}
