import {ActionActionsTypes, IAction} from "../interfaces/action";
import {AnyAction} from "@reduxjs/toolkit";

interface IActionState {
  action: IAction | null
}

const initial: IActionState = {
  action: null
}

export const Action = (state = initial, action: AnyAction) => {
  switch (action.type) {
    case ActionActionsTypes.CREATE:
      return {
        ...state,
        ...{
          action: action.payload
        }
      };
    case ActionActionsTypes.DELETE:
      return {
        ...state,
        ...{
          action: null
        }
      };
    default:
      return state
  }
}