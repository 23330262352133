import {AlertActionsTypes, IAlert} from "../interfaces/alert";
import {AnyAction} from "@reduxjs/toolkit";

interface IAlertState {
  events: Array<IAlert>
}

const initial: IAlertState = {
  events: []
}

export const Alert = (state = initial, action: AnyAction) => {
  switch (action.type) {
    case AlertActionsTypes.SUCCESS:
    case AlertActionsTypes.WARNING:
    case AlertActionsTypes.ERROR:
      return {
        ...state,
        ...{
          events: [...state.events, action.payload]
        }
      };
    case AlertActionsTypes.SHIFT:
      return {
        ...state,
        ...{
          events: state.events.filter((_, index) => !!index)
        }
      }
    case AlertActionsTypes.RESET:
      return {
        ...state,
        ...{
          initial
        }
      }
    default:
      return state
  }
}