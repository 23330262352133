import React, {ReactElement, useState} from "react"
import {Merchant} from "../../Merchant";
import {IMerchant} from "../../../../interfaces/merchant";
import {Edit as EditIcon} from "@mui/icons-material";
import {Grid, ListItemIcon, ListItemText} from "@mui/material";
import {MenuItem} from "../../../../../App/components/Menu";

interface Props {
  merchant: IMerchant,
  onChange?: (merchant: IMerchant) => void
}

export function Edit(props: Props): ReactElement | null {
  const {merchant, onChange} = props
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Grid item>
      <MenuItem
        onClick={() => {
          handleOpen()
        }}
      >
        <ListItemIcon>
          <EditIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText>Редактировать</ListItemText>
      </MenuItem>
      {open ? (
        <Merchant
          merchant={merchant}
          open={open}
          onChange={(merchant: IMerchant) => {
            if (onChange) {
              onChange(merchant)
            }
          }}
          onClose={() => {
            setOpen(false);
          }}
        />
      ) : null}
    </Grid>
  )
}
