import {GatewayServices} from "../services/gateway";
import {Dispatch} from "../../App/reducers/store";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {IFilter} from "../../App/interfaces/filter";
import {IData} from "../../App/interfaces/data";
import {AlertActionsTypes} from "../../App/interfaces/alert";
import {ISeller} from "../interfaces/seller";
import {IGateway} from "../../Payment/interfaces/gateway";
import {IGatewayInput} from "../interfaces/inputs/gateway.inputs.interface";

const gateways = (token: string) => {
  return (dispatch: Dispatch): Promise<Array<IGateway>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return GatewayServices.gateways(token)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const items = (token: string, filter: IFilter) => {
  return (dispatch: Dispatch): Promise<IData<IGateway>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return GatewayServices.items(token, filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const create = (data: IGatewayInput) => {
  return (dispatch: Dispatch): Promise<IGateway> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return GatewayServices.create(data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS,
            payload: {
              type: "success",
              message: 'Способ оплаты добавлен',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          if (error.message) {
            dispatch({
              type: AlertActionsTypes.ERROR,
              payload: {
                type: "error",
                message: error.message,
              }
            })
          }
          reject(error)
        }
      )
  })
}

const update = (id: number, data: IGatewayInput) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return GatewayServices.update(id, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS,
            payload: {
              type: "success",
              message: 'Данные обновлены',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          if (error.message) {
            dispatch({
              type: AlertActionsTypes.ERROR,
              payload: {
                type: "error",
                message: error.message,
              }
            })
          }
          reject(error)
        }
      )
  })
}

const remove = (id: number, seller: ISeller) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return GatewayServices.delete(id, seller)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS,
            payload: {
              type: "success",
              message: 'Способ оплаты удален',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

export const GatewayActions = {
  gateways,
  items,
  create,
  update,
  delete: remove
}