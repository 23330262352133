export interface IData<T> {
  data: Array<T>,
  meta: {
    page: number,
    pages: number,
    total: number
  }
}

export const Data: IData<any> = {
  data: [],
  meta: {
    page: 0,
    pages: 0,
    total: 0
  }
}