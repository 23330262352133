import React, {ReactElement, useEffect, useState} from 'react';
import {
  Grid,
  Box,
  Container,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  ListItemButton as MUIListItemButton,
  ListItem,
  ListItemText,
  List, Divider, Paper as MUIPaper, Link
} from "@mui/material";
import {useParams} from "react-router-dom";
import {ISeller} from "../Seller/interfaces/seller";
import {useAppDispatch} from "../App/hooks/store";
import {SellerActions} from "../Seller/actions/seller";
import {IGateway} from "./interfaces/gateway";
import {GatewayActions} from "../Seller/actions/gateway";
import {InvoiceActions} from "./actions/invoice";
import {IInvoiceInput} from "./interfaces/inputs/invoice.inputs.interface";

const Background = styled(Box)(({theme}) => ({
  backgroundColor: 'rgb(243, 244, 246)'
}));

export const Content = styled(MUIPaper)(({theme}) => ({
  padding: '24px',
  backgroundColor: 'rgb(255, 255, 255)',
  color: 'rgb(33, 43, 54)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
  borderRadius: '16px'
}));

const Name = styled(Typography)(({theme}) => ({
  fontSize: '1.25rem',
  fontWeight: 500,
  lineHeight: 1.5,
  color: theme.palette.primary.main
}));

const Price = styled(Typography)(({theme}) => ({
  fontWeight: 600,
  fontSize: '20px',
  fontFeatureSettings: 'normal',
  fontVariationSettings: 'normal',
  lineHeight: '28px',
  color: 'rgb(17, 24, 39)'
}));

const Title = styled(Typography)(({theme}) => ({
  fontWeight: 500,
  fontSize: '16px',
  fontFeatureSettings: 'normal',
  fontVariationSettings: 'normal',
  lineHeight: '28px',
  color: 'rgb(17, 24, 39)'
}));

const Subtitle = styled(Typography)(({theme}) => ({
  fontWeight: 400,
  lineHeight: 1.57143,
  fontSize: '0.75rem',
  color: 'rgb(99, 115, 129)'
}));

const Description = styled(Typography)(({theme}) => ({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: 1.43
}));

const ListItemButton = styled(MUIListItemButton)(({theme}) => ({
  borderRadius: '6px',
  border: '1px solid',
  borderColor: 'rgba(38, 108, 205, 0.08)',
  '&:hover': {
    backgroundColor: `rgba(38, 108, 205, 0.08)`
  },
  '& .MuiListItemIcon-root': {
    minWidth: '24px',
    marginRight: '16px',
    color: 'rgb(99, 115, 129)'
  },
  '& .MuiListItemText-primary': {
    fontWeight: 500,
    fontSize: '14px',
    color: 'rgb(99, 115, 129)'
  },
  '&.Mui-selected': {
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main
    },
    '& .MuiListItemText-primary': {
      color: theme.palette.primary.main
    }
  }
}))

interface IData {
  token: string,
  invoice_id: string,
  amount: number,
  currency: string,
  description: string,
  email: string,
  return_url?: string
}

export function Invoice(): ReactElement | null {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const params = useParams()
  const [data, setData] = useState<IData | null>(null)
  const [seller, setSeller] = useState<ISeller | null>(null)
  const [gateways, setGateways] = useState<Array<IGateway>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [gateway, setGateway] = useState<number | null>(null)

  useEffect(() => {
    if (params.data) {
      setData(JSON.parse(atob(params.data)))
    }
  }, [params]);

  useEffect(() => {
    if (data?.token) {
      const getSeller = () => {
        return dispatch(SellerActions.info(data.token))
      }

      getSeller().then(response => {
        setSeller(response as ISeller)
      })
    }
  }, [data?.token]);

  useEffect(() => {
    if (seller) {
      const getGateways = () => {
        return dispatch(GatewayActions.gateways(seller.token))
      }

      getGateways().then(response => {
        setGateways(response)
        setLoading(true)
      })
    }
  }, [seller]);

  return (
    <Background>
      <Container>
        <Stack height="100vh" direction="column" alignItems="center" justifyContent="center">
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={10} md={8}>
              {(data && seller && loading) ? (
                <Content>
                  <Grid container direction={mobile ? "column" : "row" as "column" | "row"} alignItems="stretch" justifyContent="space-between">
                    <Grid item xs={12} sm={5.8}>
                      <Grid sx={{ height: '100%' }} container direction="column" alignItems="center" justifyContent="space-between" spacing={mobile ? 2 : 0}>
                        <Grid item>
                          {seller.site ? (
                            <Link
                              sx={{
                                fontSize: '1.25rem',
                                fontWeight: 500,
                                lineHeight: 1.5,
                                color: theme.palette.primary.main,
                                textDecoration: 'none'
                              }}
                              href={seller.site}
                            >
                              {seller.name}
                            </Link>
                          ) : <Name>{seller.name}</Name>}
                        </Grid>
                        <Grid item>
                          <Grid container direction="column" alignItems="center" justifyContent="space-between" spacing={2}>
                            <Grid item>
                              <Grid container direction="column" alignItems="flex-start" justifyContent="center" spacing={2}>
                                <Grid item>
                                  <Price>{Number(data.amount).toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: data.currency
                                  })}</Price>
                                </Grid>
                                <Grid item>
                                  <Subtitle>Назначение платежа</Subtitle>
                                  <Description>{data.description}</Description>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={mobile ? 1 : 2}>
                            {seller.documentation?.offer ? (
                              <Grid item>
                                <Link
                                  sx={{
                                    fontWeight: 400,
                                    lineHeight: 1.57143,
                                    fontSize: '0.75rem',
                                    color: 'rgb(33, 43, 54);'
                                  }}
                                  underline="hover"
                                  href={seller.documentation?.offer}
                                >
                                  Публичная оферта
                                </Link>
                              </Grid>
                            ) : null}
                            {seller.documentation?.policy ? (
                              <Grid item>
                                <Link
                                  sx={{
                                    fontWeight: 400,
                                    lineHeight: 1.57143,
                                    fontSize: '0.75rem',
                                    color: 'rgb(33, 43, 54);'
                                  }}
                                  underline="hover"
                                  href={seller.documentation?.policy}
                                >
                                  Политика конфиденциальности
                                </Link>
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider
                      variant="fullWidth"
                      orientation={mobile ? 'horizontal' : 'vertical'}
                      sx={mobile ? {
                        height: '100%',
                        backgroundColor: 'rgb(249, 250, 251)',
                        borderBottomColor: 'rgba(145, 158, 171, 0.2)',
                        borderBottomStyle: 'dashed',
                        borderBottomWidth: '1px'
                      } : {
                        backgroundColor: 'rgb(249, 250, 251)',
                        borderRightColor: 'rgba(145, 158, 171, 0.2)',
                        borderRightStyle: 'dashed',
                        borderRightWidth: '1px'
                      }}
                      flexItem
                    />
                    <Grid item xs={12} sm={5.8}>
                      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
                        {!gateways.length ?
                          <Grid item sx={mobile ? { marginTop: '24px' } : {}}>
                            <Title>Способы оплаты недоступны</Title>
                          </Grid>
                          : (
                            <React.Fragment>
                              <Grid item sx={mobile ? { marginTop: '24px' } : {}}>
                                <Title>Выберите способ оплаты</Title>
                              </Grid>
                              <Grid item>
                                <List>
                                  {gateways.map((item, index) => (
                                    <ListItem
                                      sx={{marginBottom: '8px'}}
                                      key={index}
                                      onClick={() => {
                                        if (!gateway) {
                                          setGateway(item.number)
                                          dispatch(InvoiceActions.create(seller.token, {
                                            gateway: item.number.toString(),
                                            number: data.invoice_id,
                                            amount: data.amount,
                                            currency: data.currency,
                                            description: data.description,
                                            email: data.email
                                          } as IInvoiceInput)).then((response: { link: string } | void) => {
                                            if (response) {
                                              const tab = window.open(response.link, '_blank', 'noopener,noreferrer')
                                              if (tab) tab.opener = null
                                            }
                                            setGateway(null)
                                          }).catch(() => {
                                            setGateway(null)
                                          })
                                        }
                                      }}
                                      disablePadding
                                    >
                                      <ListItemButton disabled={!!gateway} selected={item.number === gateway}>
                                        <ListItemText primary={item.method.name}/>
                                      </ListItemButton>
                                    </ListItem>
                                  ))}
                                </List>
                              </Grid>
                            </React.Fragment>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Content>
              ) : null}
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Background>
  )
}