import React, {ReactElement} from "react"
import {LinearProgress, styled} from "@mui/material";
import {useAppSelector} from "../hooks/store";

const Progress = styled(LinearProgress)(({theme}) => ({
  backgroundColor: 'linear-gradient(135deg, rgb(91, 228, 155) 0%, rgb(0, 167, 111) 100%)',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1300,
}))

export function Loading(): ReactElement | null {
  const {loading, filling} = useAppSelector(state => state.loading)

  return (loading || filling) ? (
    <Progress/>
  ) : null
}
