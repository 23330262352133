const status: { [key: string]: { key: string, name: string } } = {
  paid: {
    key: 'paid',
    name: 'Оплачен'
  },
  wait: {
    key: 'wait',
    name: 'В обработке'
  },
  canceled: {
    key: 'canceled',
    name: 'Отменен'
  },
  refunded: {
    key: 'refunded',
    name: 'Возвращен'
  },
  error: {
    key: 'error',
    name: 'Ошибка'
  }
}

export {status}