import {NotificationServices} from "../services/notification";
import {Dispatch} from "../../App/reducers/store";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {IData} from "../../App/interfaces/data";
import {INotification} from "../interfaces/notification";
import {AlertActionsTypes} from "../../App/interfaces/alert";
import {INotificationInput} from "../interfaces/inputs/notification.inputs.interface";
import {IFilter} from "../../App/interfaces/filter";

const items = (token: string, filter: IFilter) => {
  return (dispatch: Dispatch): Promise<IData<INotification>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return NotificationServices.items(token, filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const update = (id: number, data: INotificationInput) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return NotificationServices.update(id, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS,
            payload: {
              type: "success",
              message: 'Данные обновлены',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

export const NotificationActions = {
  items,
  update
}