import {HttpService} from "../../App/services/http";

const all = () => {
  return HttpService.get(`/payment/methods`)
    .then(response => {
      const {data} = response
      return data
    })
}

export const MethodServices = {
  all
}