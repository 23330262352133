import React, {ReactElement} from 'react';
import {
  Grid,
  IconButton,
  MenuItem,
} from "@mui/material";
import {Control, Controller, useFieldArray} from "react-hook-form";
import {Add, Clear} from "@mui/icons-material";
import {TextField} from "../../App/components/Input/TextField";
import {Chip} from "../../App/components/Chip";

interface Props {
  control: Control<any>,
  name: string,
  label: string,
  disabled: boolean
}

export function Redirect(props: Props): ReactElement | null {
  const {control, name, label, disabled} = props

  const {fields, append, remove} = useFieldArray({
    control,
    name: `${name}.params`
  });

  return (
    <React.Fragment>
      <Grid item sx={{height: '100%', minHeight: 80}}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <Chip label="GET"/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Controller
              name={`${name}.url`}
              control={control}
              render={({
                field: {onChange, value}, fieldState
              }) => (
                <TextField
                  disabled={disabled}
                  label={label}
                  error={!!fieldState.error}
                  onChange={onChange}
                  value={value}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <IconButton
                  onClick={() => {
                    append({name: "", value: ""})
                  }}
                >
                  <Add fontSize="small"/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {fields.map((field, index) => (
        <Grid item key={index} sx={{height: '100%', minHeight: 80}}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <IconButton
                onClick={() => {
                  remove(index)
                }}
              >
                <Clear fontSize="small" color="error"/>
              </IconButton>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name={`${name}.params.${index}.name`}
                control={control}
                render={({
                  field: {onChange, value}, fieldState
                }) => (
                  <TextField
                    disabled={disabled}
                    label="Ключ:"
                    error={!!fieldState.error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name={`${name}.params.${index}.value`}
                control={control}
                render={({
                  field: {onChange, value}, fieldState
                }) => (
                  <TextField
                    select
                    disabled={disabled}
                    label="Параметр:"
                    error={!!fieldState.error}
                    onChange={onChange}
                    value={value}
                    fullWidth
                  >
                    {[
                      {label: 'Номер заказа', value: 'number'},
                      {label: 'Идентификатор', value: 'id'},
                      {label: 'Хэш-код', value: 'hash'},
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </React.Fragment>
  )
}