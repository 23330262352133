import React, {ReactElement, useState} from 'react';
import {
  Draggable,
} from 'react-beautiful-dnd';
import {
  Control,
} from "react-hook-form";
import {IMerchant} from "../../../interfaces/merchant";
import {
  Grid,
  Accordion as MUIAccordion, AccordionDetails as MUIAccordionDetails,
  AccordionSummary as MUIAccordionSummary,
  Chip as MUIChip,
  IconButton,
  styled
} from "@mui/material";
import {Clear as ClearIcon, Settings as SettingsIcon} from "@mui/icons-material";
import {Settings} from "../Settings";

const Accordion = styled(MUIAccordion)(({theme}) => ({
  width: '100%',
  boxShadow: 'none',
  margin: 'unset',
  padding: 'unset',
  '&.MuiPaper-root': {
    borderRadius: '6px',
    '&::before': {
      display: 'none'
    }
  },
  marginBottom: '4px',
  '&.Mui-expanded': {
    margin: 'unset'
  },
  '& .MuiButtonBase-root': {
    '&:hover:not(.MuiIconButton-root):not(.MuiChip-root)': {
      cursor: 'grab'
    }
  }
}));

const AccordionSummary = styled(MUIAccordionSummary)(({theme}) => ({
  minHeight: 'auto',
  padding: 'unset',
  borderRadius: '6px',
  '& .MuiAccordionSummary-content': {
    margin: 'unset'
  },
  '&.Mui-expanded': {
    minHeight: 'auto',
    margin: 'unset'
  },
  '&:hover': {
    background: 'rgb(99, 115, 129, 0.08)'
  }
}));

const AccordionDetails = styled(MUIAccordionDetails)(({theme}) => ({
  cursor: 'auto',
  '&:hover': {
    cursor: 'auto'
  }
}));

const Chip = styled(MUIChip)(({theme}) => ({
  borderRadius: '6px',
  color: theme.palette.primary.main,
  borderColor: 'rgba(145, 158, 171, 0.2)',
  fontSize: ' 0.75rem',
  fontWeight: 500
}));

export type Props = {
  key: number,
  index: number,
  control: Control,
  merchant: IMerchant,
  disabled: boolean,
  remove: (id: number) => void
};

export function Merchant(props: Props): ReactElement | null {
  const {index, control, merchant, disabled, remove} = props
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleChange = () => {
    setExpanded(!expanded)
  }

  return (
    <Draggable draggableId={merchant.id.toString()} index={index}>
      {(provided, snapshot) => (
        <Accordion
          disableGutters
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          expanded={expanded}
        >
          <AccordionSummary
            sx={{
              background: snapshot.isDragging ? 'rgba(145, 158, 171, 0.2)' : 'transparent',
            }}
          >
            <Grid item
              sx={{
                width: '100%',
                padding: '8px',
              }}
            >
              <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <Grid item xs={6} sm={8}>
                  <Chip
                    label={merchant.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                    <Grid item>
                      <IconButton
                        disabled={disabled}
                        color={expanded ? "primary" : "default" as "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"}
                        size="small"
                        onClick={() => handleChange()}
                      >
                        <SettingsIcon/>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="small"
                        disabled={disabled}
                        onClick={() => {
                          remove(merchant.id)
                        }}
                      >
                        <ClearIcon color="error"/>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            {...{
              'data-rbd-drag-handle-context-id': provided.dragHandleProps?.["data-rbd-drag-handle-context-id"],
              'data-rbd-drag-handle-draggable-id': "gibberish"
            }}
          >
            <Settings control={control} index={index} merchant={merchant} disabled={disabled}/>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  )
}
